import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: overlay;
`;

export const Box = styled.div`
  height: 100%;
  width: 25em;

  display: flex;
  overflow-y: hidden;
  overflow-x: overlay;
`;

export const Tasks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);

  .head {
    width: 100%;
    height: 2em;
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .text {
      width: calc(100% - 2em);
      display: flex;
      align-items: center;
      svg {
        margin-left: 1em !important;
        margin-right: 0.5em !important;
      }
      p {
        font-weight: 600;
      }
    }

    .terminal {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .tasks {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 3em);
    align-items: center;
    overflow: overlay;
  }

  .cancel {
    width: 100%;
    height: 5em;
    background-color: rgba(253, 253, 253);
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: 10em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.7;
      cursor: pointer;
      transition: all 0.2s;

      p {
        font-size: 0.85em;
        opacity: 0.7;
      }

      &:hover {
        opacity: 1;
        border: 1px solid rgba(0, 200, 250, 0.3);
      }
    }
  }
`;

export const Task = styled.div`
  width: 97%;

  height: max-content;
  min-height: 6em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 0.5em !important;
  border-radius: 10px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .header {
    width: 100%;
    height: 4em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .thumb {
      width: 4em;
      height: 100%;
      display: flex;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      align-items: center;
      justify-content: center;

      .circle {
        width: 2.7em;
        height: 2.7em;
        border-radius: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.6;

        p {
          font-weight: 600;
          color: white;
          opacity: 1;
        }
      }
    }

    .text {
      width: calc(100% - 4em);
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-left: 10px;

      p {
        line-height: 17px;
        opacity: 0.8;
        font-size: 0.9em;
        font-weight: 500;

        &:last-child {
          font-size: 0.85em;
          font-weight: 300;
        }
      }
    }
  }

  .infos {
    width: 100%;
    height: 1.3em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .info {
      width: max-content;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      p {
        font-size: 0.85em;
        opacity: 0.8;
        margin-left: 7px !important;
      }

      svg {
        font-size: 0.9em;
        opacity: 0.5;
      }
    }
  }

  .last-log {
    width: 100%;
    height: 3em;
    display: flex;

    .icon {
      min-width: 2.5em;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 2em;
        height: 2em;
        border-radius: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.6;

        p {
          font-weight: 600;
          color: white;
          opacity: 1;
        }
      }
      svg {
        opacity: 0.6;
      }
    }

    .text {
      min-width: 17.5em;
      height: 3em;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;

      p {
        font-size: 0.9em;
        margin-left: 5px !important;
        opacity: 0.7;
      }
    }

    .date {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 0.8em;
        opacity: 0.8;
      }
    }
  }
  .method {
    width: 100%;
    height: 100%;
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;

    p {
      font-size: 0.85em;
      opacity: 0.8;
      margin-left: 7px !important;
    }
    .path {
      width: 30%;
      height: 100%;
      display: flex;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      align-items: center;
    }
    .app {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`;
