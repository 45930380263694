import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import alerta from "../utils/alerta";

const UsersContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function UsersProvider({ children }) {
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [userContainer, setUserContainer] = useState('users');

  function getUsers() {
    setUsers([]);
    axios.get(`${uri}/admin/users`).then((res) => {
      setUsers(res.data);
    });
  }

  function newUser(user, func) {
    axios
      .post(`${uri}/register`, user)
      .then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success", () => {});
          getUsers();
          func();
        }
      })
      .catch((e) => {
        alerta(e, false, "err", () => {});
      });
  }

  function editUser(user, func) {
    axios
      .post(`${uri}/admin/edituser`, user)
      .then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success", () => {});
          getUsers();
          func();
        }
      })
      .catch((e) => {
        alerta(e, false, "err", () => {});
      });
  }

  function deleteUser(user, func) {
    axios
      .post(`${uri}/admin/deleteuser`, { user })
      .then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success", () => {});
          getUsers();
          func();
        }
      })
      .catch((e) => {
        alerta(e, false, "err", () => {});
      });
  }

  useEffect(() => {
    getUsers();
    setUser(localStorage.getItem("user_id"));
  }, []);

  return (
    <UsersContext.Provider
      value={{
        getUsers,
        users,
        setUsers,
        newUser,
        user,
        setUser,
        editUser,
        deleteUser,
        userContainer,
        setUserContainer,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export function useUsers() {
  const context = useContext(UsersContext);
  const {
    getUsers,
    users,
    setUsers,
    newUser,
    user,
    setUser,
    editUser,
    deleteUser,
    userContainer,
    setUserContainer,
  } = context;
  return {
    getUsers,
    users,
    setUsers,
    newUser,
    user,
    setUser,
    editUser,
    deleteUser,
    userContainer,
    setUserContainer,
  };
}
