import styled from "styled-components";

export const Alerta = styled.div`
  min-width: 20em;
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  background-color: white;
  z-index: 1;
  transform: translateY(-3em);
  transition: all 1s;

  &.ativ {
    transform: translateY(0);
  }

  .content {
    display: flex;
    height: 2.5em;
    justify-content: center;
    .thumb {
      width: 2.5em;
      height: 2.5em;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      width: calc(100% - 2.5em);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;


      p {
        margin-left: 0.5em !important;
        margin-right: 4em !important;
  
      }
    }
  }

  .progress {
    width: 100%;
    height: .4em;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: hidden;

    .load {
        width: 0%;
        height: 100%;
        background-color: rgba(0, 200, 100);
    }
  }


`;
