import { useApp } from "../../hooks/app";

import { Container, Display } from "./styles";
import Menu from "./components/menu";
import TasksComponent from "./components/tasks";
import Files from "./components/files";
import Projetos from "./components/projetos";
import Clients from "./components/clients";
import Monitor from "./components/monitor";
import Suporte from "./components/suport";
import Progress from "./components/progress";
import Updates from "./components/update";
import Compensacoes from "./components/compensacoes";

export default function Dash() {
  const { status } = useApp();

  const stages = [
    { tipo: "tasks", component: <TasksComponent /> },
    { tipo: "files", component: <Files /> },
    { tipo: "tax", component: <Compensacoes /> },
    { tipo: "projetos", component: <Projetos /> },
    { tipo: "suport", component: <Suporte /> },
    { tipo: "progress", component: <Progress /> },
    { tipo: "update", component: <Updates /> },
    { tipo: "clients", component: <Clients /> },
    { tipo: "monitor", component: <Monitor /> },
  ];

  return (
    <Container>
      {stages.find((obj) => obj.tipo === status).component}
      <Menu />
    </Container>
  );
}
