import styled from "styled-components";

export const Tela = styled.div`
  width: 100%;
  height: 100vh;
  display: none;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 35;

  &.ativ {
    display: flex;
    opacity: 1;
  }
`;

export const Alert = styled.div`
  width: 30em;
  color: black;
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);

  p,
  h2 {
    margin: 0 20px !important;
    margin-top: 10px !important;
  }
  h2 {
    font-size: 1.2em;
    height: max-content;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 20px !important;
    svg {
      font-size: 0.9em;
      margin-right: 15px;
    }
  }
`;

export const Btns = styled.div`
  width: 97%;
  height: 6em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Btn = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  margin: 0 0.3em !important;
  cursor: pointer;
  user-select: none;
  background-color: rgb(255, 255, 255);
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &.sim {
    color: white;
    background-color: rgba(15, 15, 15, 0.8);
  }

  p {
    font-size: 0.9em;
    margin: 0 !important;
    padding: 1px 50px !important;
  }
`;
