import React, { useState, useEffect } from "react";

import axios from "axios";

import { Container, Block, Header } from "./styles";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

import ontax from "./imgs/ontax.png";

import { BsLock, BsPerson, BsEye, BsEyeSlash } from "react-icons/bs";
import { AiOutlineIdcard } from "react-icons/ai";
import { IoIosPerson } from "react-icons/io";
import { VscLock } from "react-icons/vsc";
import { useAuth } from "../../hooks/auth";
import google from "./imgs/google.png";
import alerta from "../../utils/alerta";

import Particle from "../particles";

export default function Log() {
  const { setLogin, login, tryLogin, tryLoginGoogle } = useAuth();

  async function tryLog() {
    var email = document.getElementById("loginEmail").value;
    var password = document.getElementById("loginPass").value;

    const user = {
      email,
      senha: password,
    };

    await tryLogin(user);
  }



  function changeType() {
    var btn = document.getElementById("pass-eye");
    var antiBtn = document.getElementById("pass-anti-eye");
    var input = document.getElementById("loginPass");

    if (input.type === "password") {
      input.type = "text";
      btn.style.display = "none";
      antiBtn.style.display = "flex";
    } else {
      input.type = "password";
      btn.style.display = "flex";
      antiBtn.style.display = "none";
    }
  }

  async function onSuccess(res) {
    await tryLoginGoogle(res.profileObj, gapi.auth.getToken().access_token);
  }

  function onFailure(res) {
    alerta("Usuário sem acesso", false, "err", () => {});
  }

  useEffect(() => {
    (async() => {
      setTimeout(() => {
        try {
          document.getElementById("login").style.display = "flex";
        } catch (e) {}
      }, 1000);
    })()
  }, []);

  return (
    <Container id="login">
      <Particle />
      <Block>
        <div className="content">
          <div className="header">
            <img src={ontax}></img>
          </div>
          <GoogleLogin
            clientId={process.env.REACT_APP_CLIENT_ID}
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <div className="btn-google" onClick={renderProps.onClick}>
                <div className="thumb">
                  <img src={google} />
                </div>
                <div className="text">
                  <p>Login com Google</p>
                </div>
              </div>
            )}
          />
          <div className="inputs">
            <div className="input-box">
              <div className="thumb">
                <AiOutlineIdcard />
              </div>
              <input id="loginEmail" placeholder="Email" />
            </div>
            <div className="input-box">
              <div className="thumb">
                <VscLock />
              </div>
              <input id="loginPass" type="password" placeholder="Senha" />
              <div className="thumb end" onClick={changeType}>
                <BsEye id="pass-eye" />
                <BsEyeSlash style={{ display: "none" }} id="pass-anti-eye" />
              </div>
            </div>
          </div>
          <div className="login-box">
            <div className="btn" onClick={tryLog}>
              <h2>Login</h2>
            </div>
          </div>
        </div>
      </Block>
    </Container>
  );
}
