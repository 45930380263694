import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

const EmpresasContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function EmpresasProvider({ children }) {
  const [empresas, setEmpresas] = useState([]);

  function getEmpresas() {
    axios.get(`${uri}/admin/empresas`).then((res) => {
      setEmpresas(res.data);
    });
  }

  useEffect(() => {
    getEmpresas();
  }, []);

  return (
    <EmpresasContext.Provider
      value={{
        getEmpresas,
        empresas,
      }}
    >
      {children}
    </EmpresasContext.Provider>
  );
}

export function useEmpresas() {
  const context = useContext(EmpresasContext);
  const { getEmpresas, empresas } = context;
  return { getEmpresas, empresas };
}
