import { Container, Projeto } from "../styles";

import { useFiles } from "../../../../../hooks/files";
import { useProjetos } from "../../../../../hooks/projetos";
import { useEffect } from "react";

import { CgLoadbarDoc } from "react-icons/cg";
import { FiDatabase } from "react-icons/fi";

export default function ProjetosComponent() {
  const { projetos, setFilter } = useFiles();
  const { projetos: projs } = useProjetos();

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  useEffect(() => {

  }, [projetos, projs]);

  return (
    <Container>

      <div className="content top">
        {projs.length > 0 &&
          projetos.length > 0 &&
          projetos.map((obj) => (
            <Projeto onClick={() => setFilter(obj.projeto)}>
              <div className="head">
                <div className="thumb">
                  <div
                    className="circle"
                    style={{
                      backgroundColor: projs.find(
                        (proj) => proj.data._id === obj.projeto
                      ).color,
                    }}
                  >
                    <h1>{obj.nome.slice(0, 1)}</h1>
                  </div>
                </div>
                <div className="content-head">
                  <p>{obj.nome}</p>
                  <p className="cnpj">{obj.cnpj}</p>
                </div>
              </div>
              <div className="infos">
                <div className="info">
                  <CgLoadbarDoc />
                  <p>{obj.files}</p>
                </div>
                <div className="info">
                  <FiDatabase />
                  <p>{formatBytes(obj.size)}</p>
                </div>
              </div>
            </Projeto>
          ))}
      </div>
    </Container>
  );
}
