import { Container } from "./styles";
import { useTools } from "../../hooks/tools";

import NewUser from "./new_user";
import NewProject from "./new_project";
import User from "./user";
import ShowScreen from "./screen";
import Projeto from "./projeto";
import Terminal from "./terminal";
import NewWorker from "./new_worker";
import Update from "../dashboard/components/update/components/update";
import NewUpdate from "./update";
import ProjetoInfo from "./projeto_info";

export default function BlackScreen() {
  const { status } = useTools();
  const stages = [
    {
      type: "new_user",
      component: <NewUser />,
    },
    {
      type: "user",
      component: <User />,
    },
    {
      type: "screen",
      component: <ShowScreen />
    },
    {
      type: "new_project",
      component: <NewProject />,
    },
    {
      type: "projeto",
      component: <Projeto />,
    },
    {
      type: "projeto_info",
      component: <ProjetoInfo />,
    },
    {
      type: "terminal",
      component: <Terminal />,
    },
    {
      type: "new_worker",
      component: <NewWorker />,
    },
    {
      type: "update",
      component: <NewUpdate />,
    },
  ];
  return (
    <Container id="blk-scrn">
      {status !== undefined &&
        status !== "" &&
        stages.find((obj) => obj.type === status).component}
    </Container>
  );
}
