import { Container, Box, Tasks, Task, Menu, Projetos } from "./styles";

import axios from "axios";

import { HiUsers } from "react-icons/hi";
import { AiFillProject } from "react-icons/ai";
import { BsListTask, BsPlusLg, BsThreeDots } from "react-icons/bs";
import { FaBuilding } from "react-icons/fa";
import {
  HiUpload,
  HiIdentification,
  HiOutlineReceiptTax,
} from "react-icons/hi";
import { FaDatabase } from "react-icons/fa";
import {
  AiOutlineIdcard,
  AiOutlineCrown,
  AiOutlineDatabase,
} from "react-icons/ai";
import { VscLibrary, VscPulse } from "react-icons/vsc";
import { MdCheck } from "react-icons/md";
import { IoDocumentTextOutline, IoBusiness, IoOptions } from "react-icons/io5";
import { BsTerminalFill } from "react-icons/bs";
import { BiMemoryCard } from "react-icons/bi";

import { useTools } from "../../../../hooks/tools";
import { useTasks } from "../../../../hooks/tasks";
import { useProjetos } from "../../../../hooks/projetos";
import { useEmpresas } from "../../../../hooks/empresas";
import { useUsers } from "../../../../hooks/users";

import alerta from "../../../../utils/alerta";
import Loading from "../../../../utils/loading";
import WorkerComponent from "./components/workers";
import LogsComponent from "./components/logs";

const uri = process.env.REACT_APP_API_URI;

export default function TasksComponent() {
  const { openTools } = useTools();
  const { users, setUser } = useUsers();
  const { projetos, setProjeto, setAtualize } = useProjetos();
  const { empresas } = useEmpresas();
  const { tasks, infos } = useTasks();

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  function subs(str) {
    if (str.includes("\\")) {
      return str.substring(str.lastIndexOf("\\") + 1);
    }

    if (str.includes("/")) {
      return str.substring(str.lastIndexOf("/") + 1);
    }
  }

  function progress(prog, goal) {
    var total = goal;
    var coc = total / 100;

    return prog / coc;
  }

  function return_tasks(array, filter) {
    return array
      .filter((a) => a.status === filter)
      .map((task) => (
        <div className={`task-container ${filter}`}>
          <div className="task">
            <div className="thumb">
              {task.status === "done" && <MdCheck className="green" />}
              {task.status === "todo" && <BsThreeDots />}
              {task.status === "doing" && Loading(100)}
            </div>
            <div className="content">
              <h2>{task.tipo}</h2>
              <p>
                {task.status === "doing" && (
                  <>
                    {!["count_xml", "upload_nfes"].includes(task.tipo) &&
                      `${task.progress.toLocaleString()}/ ${task.goal.toLocaleString()}`}

                    {task.tipo === "count_xml" &&
                      task.progress.toLocaleString()}
                    {task.tipo === "upload_nfes" &&
                      `${formatBytes(task.progress)} / ${formatBytes(
                        task.goal
                      )}`}
                  </>
                )}

                {task.status === "done" && (
                  <>
                    {!["upload_nfes", "unzip", "count_xml"].includes(
                      task.tipo
                    ) && task.goal}
                    {task.tipo === "upload_nfes" && formatBytes(task.goal)}
                    {task.tipo === "count_xml" && task.progress}
                  </>
                )}
              </p>
            </div>
          </div>
          {task.status === "doing" &&
            !["unzip", "count_xml"].includes(task.tipo) && (
              <div className="progress">
                <div
                  className="load-bar"
                  style={{
                    width: `${progress(task.progress, task.goal)}%`,
                  }}
                ></div>
              </div>
            )}
          {task.tipo === "unzip" &&
            task.logs !== undefined &&
            task.logs.length > 0 && (
              <div className="logs">
                {task.logs !== undefined &&
                  task.logs
                    .filter((obj) => obj.status !== "done")
                    .sort((a, b) => a.status === "doing")
                    .map((log) => (
                      <div className="log-container">
                        <div className="log">
                          <div className="thumb">
                            <VscLibrary />
                          </div>
                          <div className="text">
                            <p>{subs(log.file)}</p>
                          </div>
                        </div>
                        {log.status === "doing" && (
                          <div className="progress">
                            <div
                              className="load-bar"
                              style={{
                                width: `${progress(log.progress, log.goal)}%`,
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            )}
        </div>
      ));
  }

  function handleReset(projeto) {
    function start() {
      axios.get(`${uri}/projetos/reset/${projeto}`).then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success");
        }
      });
    }

    alerta(
      "Tem certeza que deseja cancelar as tarefa atual?",
      true,
      "err",
      start
    );
  }

  return (
    <Container>
      <Box>
        <Tasks>
          {infos !== undefined && (
            <div className="infos">
              <div className="btn">
                <VscPulse />
                <p className="info">CPU</p>
                <p>{infos.cpu}%</p>
              </div>
              <div className="btn">
                <BiMemoryCard />
                <p className="info">MEM</p>
                <p>{infos.memory}%</p>
              </div>
              <div className="btn">
                <AiOutlineDatabase />
                <p className="info">DISK</p>
                <p>{infos.disk} GB</p>
              </div>
            </div>
          )}
          <div className="head">
            <div className="text">
              <BsListTask />
              <p>Tarefas</p>
            </div>
            <div className="terminal" onClick={() => openTools("terminal")}>
              <BsTerminalFill />
            </div>
          </div>
          <div className="tasks">
            {tasks.length !== 0 ? (
              tasks.map((obj) => (
                <Task>
                  <div className="header">
                    <div className="thumb">
                      <div
                        className="circle"
                        style={{
                          backgroundColor: `${
                            projetos.find(
                              (proj) => obj.id === proj.data._id
                            ) !== undefined &&
                            projetos.find((proj) => obj.id === proj.data._id)
                              .color
                          }`,
                        }}
                      >
                        <h1>{obj.nome.slice(0, 1)}</h1>
                      </div>
                    </div>
                    <div className="content">
                      <h2>{obj.nome}</h2>
                      <p>{obj.cnpj}</p>
                    </div>
                  </div>
                  <div className="display">
                    {return_tasks(obj.tasks, "doing")}

                    {return_tasks(obj.tasks, "todo")}

                    {return_tasks(obj.tasks, "done")}
                  </div>
                  <div className="cancel">
                    <div className="btn" onClick={() => handleReset(obj.id)}>
                      <p>Cancelar</p>
                    </div>
                  </div>
                </Task>
              ))
            ) : (
              <></>
            )}
          </div>
        </Tasks>
      </Box>
      <WorkerComponent />
      <LogsComponent />
    </Container>
  );
}
