import React, { useEffect, useState } from "react";
import { Container, Post } from "./styles";
import axios from "axios";

import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const uri = process.env.REACT_APP_API_URI;

export default function Update({setStatus}) {
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`https://www.ontax.app/pro/notion/6cdd4a53b74e40e496bbdedcad28b867`)
      .then((res) => {
        setData(res.data);
      });
  }, []);

  return (
    <Container>
      <Post>
        <div className="header">
          <div className="btn" onClick={() => setStatus('dash')} >
            <MdOutlineKeyboardBackspace />
          </div>
          <div className="content">
            <h2>{}</h2>
          </div>
        </div>
        <div className="display">
          <div className="post-content">
            {data !== undefined && <NotionRenderer blockMap={data} />}
          </div>
        </div>
      </Post>
    </Container>
  );
}
