import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

const AppContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function AppProvider({ children }) {
  const [status, setStatus] = useState('tasks');



  return (
    <AppContext.Provider
      value={{
        status,
        setStatus,

      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = useContext(AppContext);
  const { status, setStatus } = context;
  return { status, setStatus };
}
