import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: rgba(235, 235, 235);

  .close-btn {
    width: 2em;
    height: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: fixed;
    background-color: white;
    opacity: 0.5;
    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }

    svg {
      font-size: 1em;
    }
  }
`;

export const Cards = styled.div`
  width: 100%;
  min-height: 10em;
  overflow: overlay;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  .cards-box {
    width: calc(100% / 7);
    min-width: 8em;
    height: 45%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
    margin-left: 5%;

    &:first-child {
      margin-left: 4em;
    }

    .title {
      width: 100%;
      height: 1.5em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.8em;
        font-weight: 500;
        margin-left: 0.5em !important;
        opacity: 0.7;
      }

      svg {
        margin-right: 0.5em;
        opacity: 0.5;
      }
    }

    .cards-info {
      width: 100%;
      height: calc(100% - 2em);
      display: flex;
      align-items: center;
      justify-content: start;

      p {
        font-size: 1.1em;
        display: flex;
        align-items: center;
        opacity: 0.8;
        margin-left: 1em !important;
      }
    }
  }
`;

export const Charts = styled.div`
  width: max-content;
  height: calc(100% - 30em);
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: overlay;

  .chart {
    width: max-content;
    min-width: 18em;
    height: 80%;
    border-radius: 3px;
    margin-left: 3em;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(245, 245, 245);
    overflow: hidden;

    .chart-header {
      width: 100%;
      height: 2em;
      background-color: white;
      display: flex;
      align-items: center;
      overflow: overlay;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);


      p {
        font-size: 0.8em;
        font-weight: 500;
        margin-left: 1em !important;
        opacity: 0.4;
      }
    }

    .user {
      width: 100%;
      height: calc(100% - 2em);
      background-color: white;

      .header {
        width: 100%;
        height: 4em;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .thumb {
          width: 4em;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid rgba(0, 0, 0, 0.1);

          .circle {
            width: 2.5em;
            height: 2.5em;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(0, 0, 0, 0.1);
            opacity: 0.6;

            p {
              font-weight: 600;
              color: white;
              opacity: 1;
            }
          }
        }

        .text {
          width: calc(100% - 4em);
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-left: 10px;

          p {
            line-height: 17px;
            opacity: 0.8;
            font-size: 0.85em;
            font-weight: 500;

            &:last-child {
              font-size: 0.8em;
              font-weight: 300;
            }
          }
        }
      }

      .projects {
        width: max-content;
        height: calc(100% - 4em);
        display: flex;
        flex-direction: column;
        overflow: overlay;

        .project-line {
          width: max-content;
          height: 3.5em;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;

          .border-line {
            width: 4px;
            height: 100%;
            opacity: 0.4;
          }

          .content {
            width: max-content;
            height: 100%;

            .names {
              width: max-content;
              height: 2em;
              margin-left: 7px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                font-size: 0.7em;
                font-weight: 500;
                line-height: 13px;
                opacity: 0.8;
                margin-top: 10px;

                &:last-child {
                  font-size: 0.6em;
                  font-weight: 400;
                  opacity: 1;
                }
              }
            }

            .infos {
              width: max-content;
              height: 1.5em;
              border-top: 1px solid rgba(0, 0, 0, 0.03);
              display: flex;

              .info {
                width: max-content;
                display: flex;
                border-right: 1px solid rgba(0, 0, 0, 0.03);

                .ico {
                  width: 1.5em;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  &.fa {
                    opacity: 0.5;
                    font-size: 0.8em;
                  }

                  svg {
                    opacity: 0.5;
                  }
                }

                .info-txt {
                  width: max-content;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  h2 {
                    font-size: 0.8em;
                    margin: 0 5px !important;
                    margin-right: 10px !important;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }
    }

    .index {
      width: 100%;
      height: calc(100% - 2em);
      overflow: overlay;

      .line-index {
        width: 25em;
        height: calc(100% / 7 - 1px);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background-color: white;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 0.8;
        transition: all 0.2s;

        &:hover {
          opacity: 1;
        }

        .logo {
          width: 2em;
          height: 100%;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            opacity: 0.6;
            font-size: 0.8em;
          }
        }

        .index-name {
          width: calc(100% - 3.5em);
          height: 100%;
          display: flex;
          align-items: center;
          p {
            font-size: 0.8em;
            font-weight: 400;
            margin-left: 0.8em !important;
            opacity: 0.8;
          }
        }
      }
    }

    .log-chart {
      width: 100%;
      height: calc(100% - 2em);
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .week-day {
        width: 100%;
        height: 1.5em;
        display: flex;
        background-color: white;

        .day {
          width: calc(100% / 7);
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          opacity: 0.8;

          &:last-child {
            border-right: none;
          }

          p {
            font-size: 0.6em;
            opacity: 0.8;
          }
        }
      }

      .chart-container {
        width: 100%;
        height: calc(100% - 1.5em);
        display: flex;
        .log-day {
          width: calc(100% / 7);
          height: 100%;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          cursor: pointer;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          opacity: 0.6;
          transition: all 0.2s;

          &:last-child {
            border-right: none;
          }

          &:hover {
            opacity: 1;
          }

          .chart-value {
            width: 50%;
            height: 50%;
            background-color: aqua;
            opacity: 0.6;
          }
        }
      }
    }
  }
`;

export const Infos = styled.div`
  width: 100%;
  max-height: 25em;
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    width: 100%;
    height: 2em;
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .item {
      width: 15em;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      &.desc {
        min-width: 30em;
      }
    }

    p {
      font-size: 0.7em;
      font-weight: 600;
      margin-left: 0.5em !important;
      opacity: 0.4;

      display: flex;
      align-items: center;

      &:last-child {
        height: 100%;
        width: 13%;
      }
    }
  }

  .logs {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: overlay;
    display: flex;
    flex-direction: column-reverse;

    .line {
      width: calc(100%);
      min-height: 2em;
      height: 2em;
      display: flex;
      opacity: 0.6;
      cursor: pointer;
      background-color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      transition: all 0.2s;

      &:hover {
        opacity: 1;
        border-bottom: 1px solid rgba(0, 200, 250, 0.2);
      }

      .item {
        height: 100%;
        width: 15em;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;

        p {
          margin-left: 10px !important;
          font-size: 0.8em;
        }

        &.desc {
          min-width: 30em;
        }
      }
    }
  }
`;
