import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .month {
    display: flex;
    width: 100%;
    height: 3em;
    align-items: center;
    .head {
      display: flex;
      align-items: center;
      flex-direction: row;

      P {
        font-weight: 600;
        font-size: 1em;
      }
    }
    .btns {
      cursor: pointer;
      display: flex;
      height: 1em;
      svg {
        margin: 0 10px;
        font-size: 1em;
      }
    }
    p {
      font-weight: 600;
      font-size: 1em;
    }
    .calendar {
      display: none;
      margin: 0 10px;
    }
    .data {
      display: flex;
      justify-content: flex-end;

      width: 80%;
      height: 100%;
      .dashboard {
        display: flex;
        width: 40%;

        .box {
          flex-direction: column;
          border-top: 1px solid rgba(0, 0, 0, 0.1);

          border-left: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          display: flex;
          width: 33.3%;
          background-color: rgba(253, 253, 253);
          .tittle {
            display: flex;
            height: 40%;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            p {
              opacity: 0.8;
              margin-left: 0.5em !important;
              font-size: 0.6em;
              font-weight: 600;
            }
          }
          .content {
            display: flex;
            height: 60%;
            align-items: center;
            p {
              opacity: 0.8;
              margin-left: 0.7em !important;
              font-size: 0.9em;
              font-weight: 500;
            }
          }
        }
        &:last-child {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .display {
    height: 100%;
    overflow: overlay;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .content {
      overflow: overlay;
      scroll-behavior: smooth;
      width: 97%;
      height: max-content;
      max-height: calc(100% - 2em);
      display: flex;

      padding-bottom: 2em;
    }
  }
`;

export const Box = styled.div`
  width: calc(100% - 10em);
  height: calc(100% - 1.5em);
  display: flex;
  overflow-y: hidden;
  overflow-x: overlay;
  flex-direction: column;
  .display-cards {
    width: 100%;
    height: calc(100% - 2em);
    overflow: overlay;
    display: flex;
    flex-direction: column;
    align-items: center;
    &::-webkit-scrollbar {
      width: 3px;
      height: 7px;
    }
  }
  .days {
    display: none;
    width: 100%;
    height: 1.4em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    align-items: center;
    .dias {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 7);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      P {
        font-size: 0.8em;
        opacity: 0.8;
      }
    }
  }
  .display {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: overlay;
    background-color: rgb(250, 250, 250);
    .box {
      height: 10.7em;
      display: flex;
      flex-direction: column;
      max-width: calc(100% / 7);
      width: calc(100% / 7);

      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .head {
        justify-content: center;
        display: flex;
        min-height: 1.5em;
        width: 100%;
        background-color: white;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &.ativ {
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.4em;
            height: 1.4em;
            border-radius: 100%;
            background-color: rgb(0, 100, 200);
            color: white;
          }
        }
        p {
          font-size: 0.8em;
        }
      }
      .content {
        height: max-content;
        display: flex;
        overflow: overlay;
        width: 100%;

        align-items: center;
        flex-direction: column;
        ::-webkit-scrollbar {
          width: 1px;
          height: 7px;
        }

        .card {
          display: flex;
          width: 95%;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          height: max-content;
          transform: translateY(1em);
          min-height: max-content;
          margin-bottom: 10px !important;
          background-color: white;
          flex-direction: column;

          .infos {
            display: flex;
            width: 100%;
            height: max-content;
            .text {
              display: flex;
              width: calc(100% - 2em);
              height: max-content;
              min-height: 2em;
              align-items: center;
              p {
                margin-left: 5px !important;
              }
            }
            .nome {
              width: 2em;
              align-items: center;
              justify-content: center;
              display: flex;
              .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: green;
                color: white;
                width: 1.5em;
                height: 1.5em;
                border-radius: 100%;
              }
            }
            p {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              font-size: 0.7em;
            }
          }
          .datas {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            width: 100%;
            height: 1em;
            align-items: center;
            justify-content: flex-end;
            .icons {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: flex-end;
              align-items: center;
              svg {
                font-size: 0.8em;
              }
            }
            p {
              margin: 0 7px !important;
              font-weight: 500;
              font-size: 0.6em;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
`;

export const Tasks = styled.div`
  width: 25em;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  align-items: center;

  &:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.03);
  }
  .dias {
    display: flex;
    flex-direction: column;
  }
  .head {
    width: 100%;
    height: 2em;
    background-color: white;
    display: flex;
    align-items: center;

    .text {
      width: calc(100% - 2em);
      display: flex;
      align-items: center;
      svg {
        margin-left: 1em !important;
        margin-right: 0.5em !important;
      }
      p {
        font-weight: 600;
        margin-left: 1em !important;
      }
    }
  }
`;

export const Task = styled.div`
  background-color: white;
  width: 90%;
  min-height: 10em;
  height: max-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  margin-top: 0.5em !important;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  &:last-child {
    margin-bottom: 1em !important;
  }

  .content {
    width: 95%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .text {
      width: 90%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: calc(100% - 3em);
      p {
        margin-top: 1em !important;
      }
    }
    .infos {
      gap: 0.5em;
      align-items: center;
      width: 100%;
      height: 3em;
      display: flex;

      .info-data {
        display: flex;
        align-items: center;
        width: max-content;
        height: 100%;
        padding: 0 10px !important;
        p {
          font-size: 0.8em;
        }
        svg {
          margin-right: 5px !important;
          &.green {
            color: green;
          }
        }
      }
    }
  }
`;
