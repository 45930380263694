import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`


export const Content = styled.div`
  width: 90%;
  height: 90%;
  overflow: hidden;
  display: flex;

  .btn {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        color: white;
        font-size: 2em;
    }
  }
`;
