import styled from "styled-components";

export const Container = styled.div`
  width: 90.6%;
  height: 100%;
  overflow: overlay;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .header {
    width: 100%;
    height: 3em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    p {
      font-size: 1.5em;
      margin-left: 1em !important;
      font-weight: 600;
      opacity: 0.6;
    }
  }

  .content {
    width: calc(100% - 2em);
    height: calc(100% - 3em);
    display: flex;

    .item-list {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: start;
      justify-content: start;
      flex-wrap: wrap;
      overflow: overlay;
    }

    .show-list {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Item = styled.div`
  width: calc(100% / 2 - 2px);
  height: 10em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.8;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
`;

export const Show = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .principal {
    width: 100%;
    height: 55%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
/*       transform: translateX(8em) translateY(-2em); */
    }
  }

  .others {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .screen {
      width: 50%;
      height: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
