import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(248, 248, 248);
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .btn {
    width: max-content;
    height: 2em;
    align-items: center;
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 200, 200, 0.3);
    background-color: white;
    margin-left: 1em;
    padding: 0 20px !important;
    opacity: .7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
    p {
      font-size: 0.7em;
      font-weight: 600;
      opacity: 0.8;
      margin-left: 7px !important;
      padding-right: 10px !important;
    }

    svg {
      font-size: .8em;
    }
  }
`;

export const Display = styled.div`
  width: 100%;
  height: calc(100% - 3em);
  overflow: overlay;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: 15em;
  height: 15em;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 1em;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .thumb {
    width: 100%;
    height: 5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .content {
    width: 100%;
    height: calc(100% - 7em);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: column;

    .title {
      width: 100%;
      height: 3em;
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 1em;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .desc {
      width: 100%;
      height: 4em;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: .9em;
        width: 90%;
        line-height: 18px;
      }
    }
  }

  .infos {
    width: 100%;
    height: 2em;
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
