import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: overlay;

  .display {
    width: 100%;
    height: 100%;
    overflow: overlay;
    display: flex;
    justify-content: center;

    .content {
      height: max-content;
      max-height: calc(100% - 2em);
      display: flex;
      flex-wrap: wrap;

    }
  }
`;


export const Item = styled.div`
  width: calc(100% / 4.2);
  min-width: 21em;
  height: max-content;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-left: 1em;
  margin-top: 1em;
  opacity: 0.8;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s;
  &:last-child {
    margin-bottom: 1em !important;
  }

  &:hover {
    opacity: 1;
    border: 1px solid rgba(0, 200, 255, 0.4);
  }

  .infos-container {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;

    .header {
      width: 100%;
      height: 4em;
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .thumb {
        height: 100%;
        min-width: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 0.6em;

        .circle {
          width: 2.6em;
          height: 2.6em;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-weight: 600;
          color: white;
          opacity: .8;
          
          
        }
      }

      .text {
        width: calc(100% - 4em);
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        p {
          font-size: 0.8em;
          line-height: 18px;

          &:last-child {
            opacity: 0.8;
          }
        }
      }
    }

    .infos {
      width: 100%;
      height: calc(100% - 6em);
      display: flex;
      flex-direction: column;
      align-items: center;

      .line-info {
        width: 92%;
        height: 1em;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 0.5em;

        &:last-child {
          margin-bottom: 1.5em;
        }

        svg {
          font-size: 0.7em;
          opacity: 0.6;
        }

        .info-title {
          font-weight: 500;
          opacity: 0.7;
        }
      }

      p {
        font-size: 0.8em;
        opacity: 0.8;
      }
    }
  }

  .footer {
    width: 100%;
    height: 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;

    .edit-btn {
      width: 2em;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      transition: all 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
    .rest-footer {
      width: calc(100% - 2em);
      height: 100%;
    }
  }
`;
