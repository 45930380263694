import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import alerta from "../utils/alerta";

const ProjetosContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function ProjetosProvider({ children }) {
  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState();
  const [atualize, setAtualize] = useState(false);

  function getProjetos() {
    setProjetos([]);
    axios.get(`${uri}/admin/projetos`).then((res) => {
      setProjetos(res.data);
      console.log(res.data);
    });
  }

  function novoProjeto(query, func) {
    axios.post(`${uri}/projetos`, query).then((res) => {
      if (res.status === 200) {
        alerta(res.data.msg, false, "success");
        getProjetos();
        func();
      } else {
        alerta(res.data.msg, false, "err");
      }
    });
  }

  async function deleteProjeto(user, projeto, func) {
    if (func !== undefined) {
      func();
    }
    axios.post(`${uri}/projetos/delete`, { user, projeto }).then((res) => {
      if (res.status === 200) {
        alerta(res.data.msg, false, "success");
        getProjetos();
        document.getElementById("mini-alert").classList.remove("ativ");
      }

      if (res.status === 400) {
        alerta(res.data.msg, false, "err");
        document.getElementById("mini-alert").classList.remove("ativ");
        getProjetos();
      }
    });
  }

  async function editProjeto(query, func) {
    axios.post(`${uri}/projetos/edit`, query).then((res) => {
      if (res.status === 200) {
        alerta(res.data.msg, false, "success");
        getProjetos();
        func();
      } else {
        alerta(res.data.msg, false, "err");
      }
    });
  }

  useEffect(() => {
    getProjetos();
  }, []);

  return (
    <ProjetosContext.Provider
      value={{
        getProjetos,
        projetos,
        novoProjeto,
        projeto,
        setProjeto,
        deleteProjeto,
        editProjeto,
        atualize,
        setAtualize,
      }}
    >
      {children}
    </ProjetosContext.Provider>
  );
}

export function useProjetos() {
  const context = useContext(ProjetosContext);
  const {
    getProjetos,
    projetos,
    novoProjeto,
    projeto,
    setProjeto,
    deleteProjeto,
    editProjeto,
    atualize,
    setAtualize,
  } = context;
  return {
    getProjetos,
    projetos,
    novoProjeto,
    projeto,
    setProjeto,
    deleteProjeto,
    editProjeto,
    atualize,
    setAtualize,
  };
}
