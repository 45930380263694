import styled from 'styled-components';

export const Container = styled.div`
  min-width: 18em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250, 1);
`;

export const MiniMenu = styled.div`
  min-width: 3em;
  z-index: 2;
  width: 3em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250, 1);
`;

export const Logo = styled.div`
  width: 100%;
  height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  img {
    width: 13em;
  }
`;

export const Btn = styled.div`
  width: 100%;
  height: 3.5em;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.2s;
  &.logout {
    bottom: 0;
    position: absolute;
    width: 3em;
    svg {
      color: red;
    }
  }

  &:hover {
    opacity: 1;
  }

  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.ativ {
    background-color: black;
    color: white;
    opacity: 1;
  }

  .content {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 0.8em;
    font-weight: 600;
    min-width: 10em;
  }

  svg {
    margin-right: 0.5em !important;
    margin-left: 1em !important;
  }
`;

export const LogoutBtn = styled.div`
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  cursor: pointer;
  opacity: 0.7;
  &.toggle {
    transform: translateX(15.5em);
    svg {
      color: black;
      font-size: 0.9em;
    }
  }

  svg {
    color: red;
    font-size: 1.1em;
  }
  &:hover {
    opacity: 1;
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  background-color: rgba(15, 15, 15);

  svg {
    color: white;
    margin-right: 0.5em !important;
    margin-left: 1em !important;
  }

  h2 {
    color: white;
    font-size: 0.8em;
  }
`;

export const Terminal = styled.div`
  width: 100%;
  height: calc(100% - 10em);
  display: flex;
  flex-direction: column;
  background-color: rgba(40, 0, 20);

  .line {
    width: 100%;
    height: max-content;
    display: flex;
    p {
      color: white;
      padding: 5px 0 !important;
    }
  }
`;
