import React, { useState } from 'react';
import { Container } from './style';

import Users from './components/users';
import User from './components/user';
import { useUsers } from '../../../../hooks/users';

export default function Clients() {
  const { userContainer } = useUsers();
  const stages = [
    {
      name: 'users',
      comp: <Users />,
    },
    { name: 'user', comp: <User /> },
  ];

  return stages.find((obj) => obj.name === userContainer).comp;
}
