import { Container, Header, Content } from "./styles";

import { MdClose } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";
import { HiUsers, HiOfficeBuilding } from "react-icons/hi";
import { BiPlus } from "react-icons/bi";
import { HiTrash } from "react-icons/hi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { useTools } from "../../../hooks/tools";

import { useEffect, useState } from "react";
import axios from "axios";
import alerta from "../../../utils/alerta";
import { useUsers } from "../../../hooks/users";
import ContentLoader from "react-content-loader";
import { useProjetos } from "../../../hooks/projetos";

const uri = process.env.REACT_APP_API_URI;

export default function NewProject() {
  const { openTools } = useTools();
  const { users, setUsers, getUsers } = useUsers();
  const [new_users, setNewUsers] = useState([]);
  const [search, setSearch] = useState("");
  const { novoProjeto } = useProjetos();

  function handleClose() {
    openTools("new_project");
  }

  function handleNewProjeto() {
    var nome = document.getElementById("new-project-nome");
    var cnpj = document.getElementById("new-project-cnpj");
    var email = document.getElementById("new-project-email");

    var check = true;
    if (nome.value === "") {
      alerta("Insira o nome do projeto", false, "err");
      nome.classList.add("red");
      check = false;
    }

    if (cnpj.value === "") {
      alerta("Insira o CNPJ da empresa", false, "err");
      cnpj.classList.add("red");
      check = false;
    }

    if (new_users.length === 0) {
      alerta("Adicione usuários para o projeto", false, "err");
      email.classList.add("red");
      check = false;
    }

    if (check === true) {
      nome = nome.value;
      cnpj = cnpj.value;
      var new_project = {
        nome,
        cnpj,
        users: new_users.map(obj => obj._id),
      };
      function reset() {
        nome = "";
        cnpj = "";
        setNewUsers([]);
        handleClose();
        getUsers()
      }

      novoProjeto(new_project, reset);
    }
  }

  function findUser() {
    var email = document.getElementById("new-project-email").value;

    axios
      .post(`${uri}/tools/user`, { email })
      .then((res) => {
        if (res.status === 200) {
          var new_users = users.concat(res.data);
          setUsers(new_users);
        }
      })
      .catch((e) => {
        alerta("Usuário não encontrado", false, "err");
      });
  }

  function LoadPanel() {
    function LoadItem() {
      return (
        <div className="user">
          <div className="thumb">
            <ContentLoader
              viewBox="0 0 380 300"
              style={{ transform: `translateY(.1em)` }}
            >
              {/* Only SVG shapes */}
              <rect x="80" y="30" rx="5" ry="5" width="200" height="200" />
            </ContentLoader>
          </div>
          <div className="text">
            <ContentLoader
              viewBox="0 0 3500 600"
              style={{ transform: `translateY(.1em)` }}
            >
              {/* Only SVG shapes */}
              <rect x="0" y="90" rx="5" ry="5" width="3000" height="160" />
              <rect x="0" y="360" rx="5" ry="5" width="1000" height="80" />
            </ContentLoader>
          </div>
        </div>
      );
    }
    var x = 0;
    var result = [];

    while (x !== 12) {
      result.push(x);
      x++;
    }

    return (
      <>
        {result.map((obj) => (
          <LoadItem key={result.indexOf(obj)} />
        ))}
      </>
    );
  }

  function addUser(obj) {
    setNewUsers(new_users.concat(obj));
    setSearch("");
    document.getElementById("new-project-email").value = "";
  }

  function removeUser(obj) {
    setNewUsers(new_users.filter((item) => item !== obj));
  }

  return (
    <Container>
      {search !== "" && (
        <div className="users_box">
          {users.length !== 0 &&
            search !== "" &&
            users
              .filter((obj) =>
                obj.email.toLowerCase().includes(search.toLowerCase())
              )
              .map((obj) => (
                <>
                  <div className="user select" onClick={() => addUser(obj)}>
                    <div className="thumb">
                      <div className="circle">
                        <p>{obj.nome.slice(0, 1)}</p>
                      </div>
                    </div>
                    <div className="text">
                      <p>{obj.nome}</p>
                      <p className="email">{obj.email}</p>
                    </div>
                  </div>
                  <LoadPanel />
                </>
              ))}
        </div>
      )}
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="content">
          <p>Novo Projeto</p>
        </div>
      </Header>
      <Content>
        <div className="box">
          <div className="thumb">
            <IoIosPerson />
          </div>
          <input placeholder="Nome do projeto" id="new-project-nome" />
        </div>
        <div className="box">
          <div className="thumb">
            <HiOfficeBuilding />
          </div>
          <input placeholder="CNPJ" id="new-project-cnpj" />
        </div>
        <div className="box">
          <div className="thumb">
            <MdOutlineAlternateEmail />
          </div>
          <input
            onChange={(e) => setSearch(e.target.value)}
            class="add"
            placeholder="Email do participante"
            id="new-project-email"
          />

          <div className="thumb add-ico" onClick={findUser}>
            <BiPlus />
          </div>
        </div>

        <div className="box user">
          <div className="header">
            <HiUsers />
            <p>Usuários</p>
          </div>
          {new_users.length !== 0 ? (
            <div className="display">
              {new_users.map((obj) => (
                <div className="user">
                  <div className="thumb">
                    <div className="circle">
                      <p>{obj.nome.slice(0, 1)}</p>
                    </div>
                  </div>
                  <div className="text">
                    <p>{obj.nome}</p>
                    <p className="email">{obj.email}</p>
                  </div>
                  <div className="thumb delete" onClick={() => removeUser(obj)}>
                    <HiTrash />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="display-null">
              <h2>Nenhum usuário adicionado</h2>
            </div>
          )}
        </div>

        <div className="btn-container">
          <div className="btn" onClick={handleNewProjeto}>
            <h2>ENVIAR</h2>
          </div>
        </div>
      </Content>
    </Container>
  );
}
