import React, { useEffect, useState } from "react";
import { Container, Item, Show } from "./style";

import { useTools } from "../../../../../src/hooks/tools";

import axios from "axios";

export default function Monitor() {
  const [worker, setWorker] = useState();
  const [workers, setWorkers] = useState([]);

  const [telas, setTelas] = useState();

  const { openTools } = useTools();

  /*  const base64String = `${worker.data.telas[0]}` */

  useEffect(() => {
    axios.get("https://ontax.app/adm/workers").then((res) => {
      console.log(res.data);

      setWorkers(res.data);

      setWorker(res.data[0]);

      /* console.log(base64String) */
    });
  }, []);

  return (
    <Container>
      <div className="header">
        <p>Monitoramento</p>
      </div>
      <div className="content">
        <div className="item-list">
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
          <Item>tela</Item>
        </div>
        <div className="show-list">
          <Show>
            <div className="principal" onClick={() => openTools("screen")}>
              <img src={worker !== undefined && telas} />
            </div>
            <div className="others">
              <div className="screen" onClick={() => setTelas(worker.telas[0])}>
                <img src={worker !== undefined && worker.telas[0]} />
              </div>
              <div className="screen" onClick={() => setTelas(worker.telas[1])}>
                <img src={worker !== undefined && worker.telas[1]} />
              </div>
            </div>
          </Show>
        </div>
      </div>
    </Container>
  );
}
