import { useState, useEffect } from "react";
import { Container, Item, Infos, EditInfos } from "./styles";

import axios from "axios";
import { toast } from "react-toastify";

import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

import { useProjetos } from "../../../../hooks/projetos";

import percent from "../../../../utils/percent";

const uri = process.env.REACT_APP_API_URI;

export default function Compensacoes() {
  const [status, setStatus] = useState();
  const { projetos, getProjetos } = useProjetos();
  const [type, setType] = useState();

  function chooseStatus(str, opt, obj) {
    setType(opt);
    if (status !== str) {
      setStatus(str);
    } else {
      setStatus();
    }

    var total = document.getElementById(`${str}-total`);
    var recuperado = document.getElementById(`${str}-recuperado`);

    total.value = String(parseFloat(obj.impostos.total).toFixed(2))
      .replace(".", ",")
      .replace(".", "");

    recuperado.value = String(parseFloat(obj.impostos.recuperado).toFixed(2))
      .replace(".", ",")
      .replace(".", "");
  }

  function EditImposto() {
    var total = document.getElementById(`${status}-total`).value;
    var recuperado = document.getElementById(`${status}-recuperado`).value;
    total = parseFloat(total.replace(",", "."));
    recuperado = parseFloat(recuperado.replace(",", "."));
    var check = true;

    if (total === "" || recuperado === "") {
      toast.error("Valor do imposto não pode ser vazio");

      check = false;
    }

    if (isNaN(total) || isNaN(recuperado)) {
      toast.error("Valor do imposto deve ser um número válido");

      check = false;
    }

    if (check) {
      var impostos = { total, recuperado };

      axios
        .post(`${uri}/adm/projetos/${status}`, { impostos })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.msg);
            getProjetos();
            setStatus();
          }
        })
        .catch((res) => {
          toast.error(res.data.msg);
        });
    }
  }

  function AdicionaImposto() {
    var projeto = projetos.find((obj) => obj.data._id === status);

    var recuperado = document.getElementById(`${status}-add`).value;

    recuperado = parseFloat(recuperado.replace(",", "."));
    recuperado += parseFloat(projeto.data.impostos.recuperado);
    var check = true;

    if (recuperado === "") {
      toast.error("Valor do imposto não pode ser vazio");

      check = false;
    }

    if (isNaN(recuperado)) {
      toast.error("Valor do imposto deve ser um número válido");

      check = false;
    }

    if (check) {
      var total = null;
      var impostos = { total, recuperado };

      axios
        .post(`${uri}/adm/projetos/${status}`, { impostos })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.msg);
            getProjetos();
            setStatus();
          }
        })
        .catch((res) => {
          toast.error(res.data.msg);
        });
    }
  }

  function RenderItem(obj, color) {
    return (
      <Item>
        {status === obj._id && (
          <div className="header-edit">
            <div
              className="btn-close"
              onClick={() => chooseStatus(obj._id, obj)}
            >
              <IoMdClose />
            </div>
            <p>Editando informações</p>
          </div>
        )}

        <div className="header">
          <div className="thumb">
            <div className="circle" style={{ backgroundColor: color }}>
              <h1>{obj.nome.slice(0, 1)}</h1>
            </div>
          </div>
          <div className="text">
            <p className="nome">{obj.nome}</p>
            <p>{obj.cnpj}</p>
          </div>
        </div>
        <Infos
          style={{ height: `${status !== obj._id ? "max-content" : "0"}` }}
        >
          <div className="tag">
            <p>Imposto total</p>
            <p>R$ {obj.impostos.total}</p>
          </div>
          <div className="tag">
            <p>Imposto recuperado</p>
            <p>R$ {obj.impostos.recuperado}</p>
          </div>
          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${percent(
                  obj.impostos.total,
                  obj.impostos.recuperado
                )}%`,
              }}
            ></div>
          </div>
          <div className="tag">
            <p>Imposto a recuperar</p>
            <p>R$ {obj.impostos.total - obj.impostos.recuperado}</p>
          </div>
          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${percent(
                  obj.impostos.total,
                  obj.impostos.total - obj.impostos.recuperado
                )}%`,
              }}
            ></div>
          </div>
        </Infos>
        <EditInfos
          style={{ height: `${status === obj._id ? "max-content" : "0"}` }}
        >
          {type === "edit" && (
            <>
              <div className="input-line">
                <p>Imposto total</p>
                <div className="input-content">
                  <p>R$ </p>
                  <input id={`${obj._id}-total`}></input>
                </div>
              </div>
              <div className="input-line">
                <p>Imposto recuperado</p>
                <div className="input-content">
                  <p>R$ </p>
                  <input id={`${obj._id}-recuperado`}></input>
                </div>
              </div>
              <div className="btn-edit-div">
                <div className="btn-edit" onClick={EditImposto}>
                  <h2>EDITAR</h2>
                </div>
              </div>
            </>
          )}
          {type === "add" && (
            <>
              <div className="input-line">
                <p>Imposto recuperado</p>
                <div className="input-content">
                  <p>R$ </p>
                  <input id={`${obj._id}-add`}></input>
                </div>
              </div>
              <div className="btn-edit-div">
                <div className="btn-edit" onClick={AdicionaImposto}>
                  <h2>EDITAR</h2>
                </div>
              </div>
            </>
          )}
        </EditInfos>
        {status !== obj._id && (
          <div className="info-bar">
            <div
              className="info-btn"
              onClick={() => chooseStatus(obj._id, "edit", obj)}
            >
              <AiOutlineEdit />
            </div>
            <div
              className="info-btn"
              onClick={() => chooseStatus(obj._id, "add", obj)}
            >
              <AiOutlinePlus />
            </div>
          </div>
        )}
      </Item>
    );
  }

  return (
    <Container>
      <div className="display">
        <div className="content">
          {projetos.length > 0 &&
            projetos.map((obj) => RenderItem(obj.data, obj.color))}
        </div>
      </div>
    </Container>
  );
}
