import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

const ToolsContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function ToolsProvider({ children }) {
  const [status, setStatus] = useState();

  function openTools(str, func, func2) {
    setStatus(str);
    document.getElementById("blk-scrn").classList.toggle("ativ");

    if (func !== undefined) {
      func();
    }

    if (func2 !== undefined) {
      func2();
    }
  }

  return (
    <ToolsContext.Provider
      value={{
        status,
        setStatus,
        openTools,
      }}
    >
      {children}
    </ToolsContext.Provider>
  );
}

export function useTools() {
  const context = useContext(ToolsContext);
  const { status, setStatus, openTools } = context;
  return { status, setStatus, openTools };
}
