import { useState, useEffect } from "react";
import { Container, Item, Infos, EditInfos, Box, Tasks, Task } from "./styles";

import { useProjetos } from "../../../../hooks/projetos";
import { useTools } from "../../../../hooks/tools";
import { IoTimeOutline } from "react-icons/io5";
import { AiOutlineCheck } from "react-icons/ai";
import {
  AiOutlineCalendar,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineSchedule,
} from "react-icons/ai";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { MdCheck } from "react-icons/md";
import axios from "axios";
import userEvent from "@testing-library/user-event";
const uri = process.env.REACT_APP_API_URI;

export default function Progress() {
  const [cards, setCards] = useState([]);
  const [localUser, setLocalUser] = useState();
  const [localUsers, setLocalUsers] = useState();
  const [trelloUsers, setTrelloUsers] = useState();
  const [mes, setMes] = useState(new Date().getMonth());
  const [ano, setAno] = useState(new Date().getFullYear());
  const [done, setDone] = useState([]);
  const [undone, setUndone] = useState([]);

  const meses = [
    { num: 0, nome: "JANEIRO" },
    { num: 1, nome: "FEVEREIRO" },
    { num: 2, nome: "MARÇO" },
    { num: 3, nome: "ABRIL" },
    { num: 4, nome: "MAIO" },
    { num: 5, nome: "JUNHO" },
    { num: 6, nome: "JULHO" },
    { num: 7, nome: "AGOSTO" },
    { num: 8, nome: "SETEMBRO" },
    { num: 9, nome: "OUTUBRO" },
    { num: 10, nome: "NOVEMBRO" },
    { num: 11, nome: "DEZEMBRO" },
  ];
  function reduce(arr) {
    return [...new Set(arr.map((obj) => obj))];
  }
  useEffect(() => {
    axios.get(`${uri}/admin/progress`).then((res) => {
      setCards(res.data);
      var users = [];
      var done = [];
      var notdone = [];
      console.log("infos", res.data);
      res.data.map((obj) => obj.users.map((item) => users.push(item)));
      users = reduce(users);
      res.data.map((bit) => {
        if (bit.check === true) {
          done.push(bit);
          done = reduce(done);
          setDone(done);
        } else if (bit.check === false) {
          notdone.push(bit);
          notdone = reduce(notdone);
          setUndone(notdone);
        }
      });
      console.log("users", users);
    });
  }, []);

  useEffect(() => {
    getUsers();
    setLocalUser(localStorage.getItem("user_id"));
  }, []);

  function getUsers() {
    setLocalUsers([]);
    axios.get(`${uri}/admin/users`).then((res) => {
      setLocalUsers(res.data);
      console.log(res.data);
    });
  }

  function foward() {
    if (mes + 1 === 12) {
      setMes(0);
      setAno(ano + 1);
    } else setMes(mes + 1);
  }
  function backward() {
    if (mes - 1 === -1) {
      setMes(11);
      setAno(ano - 1);
    } else setMes(mes - 1);
  }
  function checkClass(num) {
    if (num === new Date().toLocaleDateString()) {
      return "ativ";
    }
  }
  const semana = [
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sabado",
    "Domingo",
  ];
  function getDaysInMonthUTC(month, year) {
    var date = new Date(Date.UTC(year, month, 2));
    var days = [];
    var x = 0;
    while (date.getUTCMonth() === month) {
      days.push(new Date(date));
      x++;
      date.setUTCDate(date.getUTCDate() + 1);
    }

    days.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

    return days;
  }

  return (
    <Container>
      <div className="month">
        <div className="btns" onClick={backward}>
          <AiOutlineLeft />
        </div>
        <div className="btns" onClick={foward}>
          <AiOutlineRight />
        </div>
        <div className="head">
          <p>{`${meses.find((obj) => obj.num === mes).nome} ${ano}`}</p>
        </div>
        <div className="calendar">
          <AiOutlineCalendar />
        </div>
        <div className="data">
          <div className="dashboard">
            <div className="box">
              <div className="tittle">
                <p>TOTAL</p>
              </div>
              <div className="content">
                <p>{cards.length}</p>
              </div>
            </div>
            <div className="box">
              <div className="tittle">
                <p>FEITOS</p>
              </div>
              <div className="content">
                <p>{done.length}</p>
              </div>
            </div>
            <div className="box">
              <div className="tittle">
                <p>A FAZER</p>
              </div>
              <div className="content">
                <p>{undone.length}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box>
        <div className="days">
          {semana.map((obj) => (
            <div className="dias">
              <p>{obj}</p>
            </div>
          ))}
        </div>
        <div className="display">
          {getDaysInMonthUTC(mes, ano).map((obj) => (
            <div className="box">
              <div className={`head ${checkClass(obj.toLocaleDateString())}`}>
                <p>{obj.getDate()}</p>
              </div>
              <div className="content">
                {cards
                  .filter(
                    (item) =>
                      item.data !== null &&
                      new Date(item.data).toLocaleDateString() ===
                        obj.toLocaleDateString()
                  )
                  .map((item) => (
                    <div className=" card">
                      <div className="infos">
                        <div className="nome">
                          <div
                            className="circle"
                            style={{
                              backgroundColor: `${
                                item.users.includes("629e814f144de86e9769564f")
                                  ? "rgba(0, 200, 100)"
                                  : "rgba(0, 100, 200)"
                              }`,
                            }}
                          >
                            <p>
                              {item.users.includes("629e814f144de86e9769564f")
                                ? "V"
                                : "H"}
                            </p>
                          </div>
                        </div>
                        <div className="text">
                          <p>{item.nome}</p>
                        </div>
                      </div>
                      <div className="datas">
                        <div className="icons">
                          {item.check === true ? (
                            <AiOutlineCheck color="green" />
                          ) : (
                            <BiTimeFive style={{ opacity: "0.5" }} />
                          )}
                        </div>
                        <p>{new Date(item.data).toLocaleDateString()}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </Box>
    </Container>
  );
}
