import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(236, 239, 244);
`;

export const Contatos = styled.div`
  width: 20em;
  height: 100%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  flex-direction: column;
  .header {
    width: 100%;
    height: 3.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    background-color: rgba(240, 240, 240);
    .thumb {
      width: 1.7em;
      height: 1.7em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 5px 0 0 5px;
      border-right: none;
    }
    input {
      width: 70%;
      height: 1.7em;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 0 5px 5px 0;
      font-size: 1em;
      border-left: none;
      padding-left: 10px !important;
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 3.5em);
    display: flex;
    flex-direction: column;
    overflow: overlay;

    .contato {
      width: 100%;
      height: 4em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      background-color: white;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      .contato-thumb {
        width: 4em;
        height: 4em;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;

        .circle {
          width: 3em;
          height: 3em;
          border-radius: 100%;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .contato-info {
        width: calc(100% - 5.45em);
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 20px !important;
        justify-content: center;

        h2 {
          font-size: 1em;
        }
        p {
          font-size: 0.8em;
          line-height: 15px;
        }
      }
    }
  }
`;

export const Chat = styled.div`
  width: calc(100% - 20em);
  height: 100%;
  display: flex;
  flex-direction: column;
  

  .header {
    width: 100%;
    height: 3.5em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    background-color: rgba(240, 240, 240);
  }

  .chat {
    width: 100%;
    height: calc(100% - 7em);
  }

  .message {
    width: 100%;
    height: 3.5em;
    display: flex;

    input {
      width: calc(100% - 3.5em);
      font-size: 1em;
      outline: none;
      border: none;
      padding-left: 2em;

    }

    .btn {
      width: 3.5em;
      height: 3.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      cursor: pointer;
      svg {
        opacity: .7;
      }
    }
  }
`;
