import { useState, useEffect } from "react";
import { Container, Item, Infos, EditInfos, Box } from "./styles";

import axios from "axios";
import { toast } from "react-toastify";

import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

import { useProjetos } from "../../../../hooks/projetos";

import percent from "../../../../utils/percent";
import { useUsers } from "../../../../hooks/users";
import alerta from "../../../../utils/alerta";
import { useTools } from "../../../../hooks/tools";

const uri = process.env.REACT_APP_API_URI;

export default function Projetos() {
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [new_users, setNewUsers] = useState([]);
  const { users, setUsers } = useUsers();
  const { projetos, getProjetos, projeto, setProjeto } = useProjetos();
  const { openTools } = useTools();

  function chooseStatus(str, opt, obj) {
    setType(opt);
    if (status !== str) {
      setStatus(str);
    } else {
      setStatus();
    }

    var total = document.getElementById(`${str}-total`);
    var recuperado = document.getElementById(`${str}-recuperado`);

    total.value = String(parseFloat(obj.impostos.total).toFixed(2))
      .replace(".", ",")
      .replace(".", "");

    recuperado.value = String(parseFloat(obj.impostos.recuperado).toFixed(2))
      .replace(".", ",")
      .replace(".", "");
  }

  function RenderItem(obj, color) {
    return (
      <Item>
        {status === obj.data._id && (
          <div className="header-edit">
            <div
              className="btn-close"
              onClick={() => chooseStatus(obj.data._id, obj.data)}
            >
              <IoMdClose />
            </div>
            <p>Editando informações</p>
          </div>
        )}

        <div className="header">
          <div className="thumb">
            <div className="circle" style={{ backgroundColor: color }}>
              <h1>{obj.data.nome.slice(0, 1)}</h1>
            </div>
          </div>
          <div className="text">
            <p className="nome">{obj.data.nome}</p>
            <p>{obj.data.cnpj}</p>
          </div>
        </div>

        {status !== obj.data._id && (
          <div className="info-bar">
            <div
              className="info-btn"
              onClick={() => openTools("projeto_info", setProjeto(obj))}
            >
              <AiOutlineEdit />
            </div>
          </div>
        )}
      </Item>
    );
  }

  return (
    <Container>
      <div className="display">
        <div className="content">
          {projetos.length > 0 &&
            projetos.map((obj) => RenderItem(obj, obj.color))}
        </div>
      </div>
    </Container>
  );
}
