import { useState } from "react";
import Dashboard from "./components/dashboard";
import Update from "./components/update";
export default function Updates() {
  const [status, setStatus] = useState("update");
  const stages = [
    { nome: "dash", component: <Dashboard /> },
    { nome: "update", component: <Update setStatus={setStatus}/> },
  ];
  return stages.find((obj) => obj.nome === status).component;
}
