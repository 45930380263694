import { BsRobot } from "react-icons/bs";
import { Container, Box, Tasks, Task } from "./styles";
import { useEffect, useRef, useState, useCallback } from "react";
import { CiLogin } from "react-icons/ci";
import axios from "axios";
import { AiOutlineRobot } from "react-icons/ai";
import { HiLogin } from "react-icons/hi";
import { BsGoogle, BsCalendar } from "react-icons/bs";
import { CgArrowsExchange } from "react-icons/cg";
import getColor from "../../../../../../utils/getColor";
import { useTasks } from "../../../../../../hooks/tasks";
import { useAuth } from "../../../../../../hooks/auth";
import { useUsers } from "../../../../../../hooks/users";
import { useProjetos } from "../../../../../../hooks/projetos";

const uri = process.env.REACT_APP_API_URI;

export default function LogsComponent() {
  const observer = useRef();
  const { users } = useUsers();
  const { projetos } = useProjetos();
  const [hasMore, setHasMore] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLogs = async () => {
      if (!hasMore || isLoading) return; // Adiciona verificação aqui
      setIsLoading(true); // Inicia o carregamento

      try {
        const res = await axios.get(`${uri}/adm/logs`, {
          params: { page, limit },
        });
        const fetchedLogs = res.data.data;

        if (fetchedLogs.length < limit) {
          setHasMore(false);
        }

        setLogs((prevLogs) => {
          // Cria um novo Set com os IDs existentes
          const logIds = new Set(prevLogs.map((log) => log._id));
          // Filtra os novos logs para incluir apenas aqueles com IDs únicos
          const uniqueLogs = fetchedLogs.filter((log) => !logIds.has(log._id));
          // Retorna a nova lista de logs, combinando os anteriores com os novos filtrados
          return [...prevLogs, ...uniqueLogs];
        });
      } catch (error) {
        console.error("Falha ao buscar logs:", error);
      } finally {
        setIsLoading(false); // Finaliza o carregamento
      }
    };

    fetchLogs();
  }, [page, limit]); // Adiciona isLoading às dependências

  const last_element = useCallback(
    (node) => {
      if (!hasMore || isLoading) return; // Não observa se não há mais itens a carregar ou já está carregando

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore, isLoading] // Adiciona isLoading às dependências
  );
  function RenderLog(obj) {
    // Acessa o _id do usuário dentro do objeto userId
    const userId = obj.userId?._id;

    // Encontra o usuário correspondente na lista de usuários
    const user = users.find((usr) => usr._id === userId);
    console.log(user);

    // Retorna o componente Task se o usuário for encontrado
    return user !== undefined && <Task ref={last_element}></Task>;
  }

  function RenderUser(obj) {
    const user = users.find((usr) => usr._id === obj.userId);
    const worker = "5f4d7641c36a5c6f44b07e84";

    if (obj.userId !== worker) {
      return (
        user !== undefined && (
          <div className="header">
            <div className="thumb">
              <div
                className="circle"
                style={{
                  backgroundColor: projetos.find(
                    (obj) => obj.data._id === user.projeto
                  ).color,
                }}
              >
                <p>{user.nome.slice(0, 1)}</p>
              </div>
            </div>
            <div className="text">
              <p>{user.nome}</p>
              <p>{user.email}</p>
            </div>
          </div>
        )
      );
    } else {
      return (
        <div className="header">
          <div className="thumb">
            <div className="circle" style={{ backgroundColor: "gray" }}>
              <p>W</p>
            </div>
          </div>
          <div className="text">
            <p>WORKER</p>
            <p></p>
          </div>
        </div>
      );
    }
  }

  return (
    <Box>
      <Tasks>
        <div className="head">
          <div className="text">
            <AiOutlineRobot />
            <p>Logs</p>
          </div>
          <div className="terminal"></div>
        </div>
        <div className="tasks">
          {logs.length > 0 &&
            logs.map((obj, index) => (
              <Task
                ref={index === logs.length - 1 ? last_element : null}
                key={obj._id}
              >
                {RenderUser(obj)}
                <div className="infos">
                  {obj.actions !== null && obj.actions !== undefined && (
                    /*  appName !== undefined &&  */ <div className="info">
                      <HiLogin />
                      {/*  <p>{obj.actions.length}</p> */}
                    </div>
                  )}
                  <div className="info">
                    <BsCalendar />
                    <p>{new Date(obj.created_at).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="last-log">
                  <div className="icon">
                    {obj.userId !== null && obj.userId !== undefined && (
                      <div
                        className="circle"
                        style={{
                          backgroundColor:
                            projetos?.find(
                              (proj) => proj.data._id === obj.userId.projeto
                            )?.color || "cor_padrao",
                        }}
                      >
                        <p>{obj.userId.nome.slice(0, 1)}</p>
                      </div>
                    )}
                  </div>
                  {obj.actions !== null && obj.actions !== undefined && (
                    <div className="text">
                      <p>
                        {obj.actions[obj.actions.length - 1] !== undefined &&
                          obj.userId.nome}
                      </p>
                    </div>
                  )}

                  <div className="date">
                    <p>
                      {new Date(obj.created_at).toTimeString().split(" ")[0]}
                    </p>
                  </div>
                </div>
                <div className="method">
                  <div className="path">
                    <p>
                      <b>Path: </b>
                      {obj.actions[obj.actions.length - 1] !== undefined &&
                        obj.actions[obj.actions.length - 1].path}
                    </p>
                  </div>
                  <div className="app">
                    <p>
                      <b>App: </b>
                      {obj.actions[obj.actions.length - 1] !== undefined &&
                        obj.actions[obj.actions.length - 1].headers.app}
                    </p>
                  </div>
                </div>
              </Task>
            ))}
          {/*  {users.length > 0 &&
            logs.length > 0 &&
            logs.map((obj) => RenderLog(obj))} */}
        </div>
      </Tasks>
    </Box>
  );
}
