import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ToolsProvider from "./hooks/tools";
import UsersProvider from "./hooks/users";
import ProjetosProvider from "./hooks/projetos";
import EmpresasProvider from "./hooks/empresas";
import TasksProvider from "./hooks/tasks";
import AuthProvider from "./hooks/auth";
import AppProvider from "./hooks/app";
import FilesProvider from "./hooks/files";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <AppProvider>
      <TasksProvider>
        <FilesProvider>
          <EmpresasProvider>
            <ProjetosProvider>
              <UsersProvider>
                <ToolsProvider>
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </ToolsProvider>
              </UsersProvider>
            </ProjetosProvider>
          </EmpresasProvider>
        </FilesProvider>
      </TasksProvider>
    </AppProvider>
  </AuthProvider>
);
