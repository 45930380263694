import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250);
  display: none;
`;

export const Header = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  position: fixed;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  top: 0;
  background-color: white;
`;

export const Block = styled.div`
  width: 25em;
  height: 28em;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.03);
  background-color: rgba(250, 250, 250);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  z-index: 3;

  .btn-google {
    width: max-content;
    height: 1.7em;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    opacity: 0.8;
    margin-bottom: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      opacity: 1;
    }
    .thumb {
      width: 1.6em;
      height: 1.6em;
      background-color: white;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 1em;
      }
    }

    .text {
      width: calc(100% - 1.65em);
    }

    p {
      font-size: 0.65em;
      font-weight: 600;
      width: 100%;
      color: black;
      padding: 5px 30px !important;
    }
  }

  .content {
    width: 85%;
    height: 90%;
    display: flex;

    flex-direction: column;

    .header {
      width: 100%;
      height: 5em;
      display: flex;
      align-items: center;

      img {
        height: 2.7em;
        transform: translateY(-0.7em);
      }
    }

    .inputs {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding-bottom: 0.5em !important;

      .input-box {
        display: flex;
        height: max-content;
        align-items: center;
        padding: 10px 0 !important;
        svg {
          color: black;
          font-size: 1.3em;

          opacity: 0.3;
        }

        &.end {
          justify-content: flex-end;
          svg {
            cursor: pointer;
            opacity: 0.2;
            font-size: 1.1em;
          }

          #pass-anti-eye {
            display: none;
          }
        }
      }

      input {
        width: 100%;
        color: black;
        padding: 0 !important;
        padding-left: 3em !important;
        height: 3em;
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-left: none;
        padding-left: 0 !important;
      }

      .thumb {
        width: 3em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-right: none;

        &.end {
          cursor: pointer;
          border-left: none;
          border-right: 1px solid rgba(0, 0, 0, 0.03);
        }

        svg {
          font-size: 1em;
        }
      }
    }

    .login-box {
      width: 100%;
      height: 3em;
      display: flex;
      align-items: center;

      .btn {
        width: max-content;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 50px !important;
        border-radius: 5px;
        background-color: black;
        cursor: pointer;
        user-select: none;
        color: white;
        margin-top: 1em;

        @media (max-width: 900px){
          font-size: .9em;
        }

        h2 {
          font-size: 1.2em;
        }
      }
    }
  }
`;
