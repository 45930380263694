import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

const TasksContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function TasksProvider({ children }) {
  const [tasks, setTasks] = useState([]);
  const [infos, setInfos] = useState();
  const [workers, setWorkers] = useState([]);
  const [logs, setLogs] = useState([]);

  async function getTasks() {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));

    var check = true;

    while (check) {
      if (localStorage.getItem("classe") !== process.env.REACT_APP_ADMIN) {
        check = false;
      }
      await axios.get(`${uri}/admin/tasks`).then(async (res) => {
        var response = res.data;
        var result = [];
        for (var i in response) {
          var task = response[i];
          var id = task.id;
          await axios.get(`${uri}/tasks/${id}`).then(async (resposta) => {
            var tasks = resposta.data;

            var new_obj = {
              nome: task.nome,
              cnpj: task.cnpj,
              id,
              tasks,
            };

            result.push(new_obj);
          });
        }

        setTasks(result);

        await timer(2000);
      });

      await axios.get(`${uri}/admin/usage`).then(async (res) => {
        setInfos(res.data);
        await timer(2000);
      });
    }
  }
  async function getWorkers() {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));

    var check = true;

    while (check) {
      if (localStorage.getItem("classe") !== process.env.REACT_APP_ADMIN) {
        check = false;
      }
      await axios.get(`${uri}/adm/workers`).then(async (res) => {
        setWorkers(res.data);

        await timer(2000);
      });
    }
  }
  async function getLogs() {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));

    var check = true;

    while (check) {
      if (localStorage.getItem("classe") !== process.env.REACT_APP_ADMIN) {
        check = false;
      }
      await axios.get(`${uri}/adm/logs`).then(async (res) => {
        setLogs(res.data);

        await timer(2000);
      });
    }
  }

  useEffect(() => {
    getTasks();
    getWorkers();
    getLogs();
  }, []);

  return (
    <TasksContext.Provider
      value={{
        infos,
        getTasks,
        tasks,
        workers,
        logs,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
}

export function useTasks() {
  const context = useContext(TasksContext);
  const { infos, getTasks, tasks, workers, logs } = context;
  return { infos, getTasks, tasks, workers, logs };
}
