import React, { useState } from 'react';
import { Container, Item, Box } from './style';

import { AiOutlineEdit, AiOutlineMail } from 'react-icons/ai';
import { BiBuildingHouse } from 'react-icons/bi';
import { MdDriveFileRenameOutline } from 'react-icons/md';

import { useUsers } from '../../../../../../hooks/users';
import { useTools } from '../../../../../../hooks/tools';
import { useProjetos } from '../../../../../../hooks/projetos';
import { useEffect } from 'react';

export default function Users() {
  const { status, setStatus } = useState();
  const { projetos } = useProjetos();
  const { users, setUser, setUserContainer } = useUsers();
  const { openTools } = useTools();
  const { setProjeto } = useProjetos();

  /* function openUser(obj) {
    setUserContainer("user");
    setUser(obj);
  } */

  useEffect(() => {
    console.log(projetos);
  }, [projetos]);

  return (
    <Container>
      <div className="display">
        <div className="content">
          {users.map((obj) => (
            <Item>
              <div
                className="infos-container"
                onClick={() => setUserContainer('user', setUser(obj))}
              >
                <div className="header">
                  <div className="thumb">
                    <div
                      className="circle"
                      style={{
                        backgroundColor:
                          projetos.find(
                            (item) => item.data._id === obj.projeto,
                          ) !== undefined &&
                          projetos.find((item) => item.data._id === obj.projeto)
                            .color,
                      }}
                    >
                      {obj.nome.slice(0, 1)}
                    </div>
                  </div>
                  <div className="text">
                    <p>{obj.nome}</p>
                    <p>{obj.email}</p>
                  </div>
                </div>
                <div className="infos">
                  <div className="line-info">
                    <MdDriveFileRenameOutline />
                    <p className="info-title">Nome:</p>
                    <p>{obj.nome}</p>
                  </div>
                  <div className="line-info">
                    <AiOutlineMail />
                    <p className="info-title">E-mail:</p>
                    <p>{obj.email}</p>
                  </div>

                </div>
              </div>
              <div className="footer">
                <div
                  className="edit-btn"
                  onClick={() => openTools('user', setUser(obj))}
                >
                  <AiOutlineEdit />
                </div>
                <div className="rest-footer"></div>
              </div>
            </Item>
          ))}
        </div>
      </div>
    </Container>
  );
}
