import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(240, 240, 240);
  overflow: hidden;
  justify-content: center;
`;


