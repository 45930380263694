import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: overlay;

  .display {
    width: 100%;
    height: 100%;
    overflow: overlay;
    display: flex;
    justify-content: center;

    .content {
      width: 100%;
      height: max-content;
      max-height: calc(100% - 2em);
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 2em;
      padding-right: 1em;
    }
  }
`;
export const Box = styled.div`
  height: 100%;
  width: 25em;

  display: flex;
  overflow-y: hidden;
  overflow-x: overlay;
`;

export const Item = styled.div`
  width: 17em;
  height: max-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-left: 1em;
  margin-top: 1em;
  opacity: 0.85;
  overflow: hidden;
  overflow-y: overlay;

  &:hover {
    opacity: 1;
  }
  .header-edit {
    width: 100%;
    height: 2em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    p {
      width: 78%;
      height: 100%;
      font-size: 0.8em;
      opacity: 0.7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-close {
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      svg {
        font-size: 0.8;
        color: black;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .header {
    width: 100%;
    height: 4em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .thumb {
      width: 4em;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      .circle {
        width: 2.5em;
        height: 2.5em;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.1);
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        opacity: 0.7;
        h1 {
          font-size: 1.1em;
          color: white;
        }
      }
    }
    .text {
      width: calc(100% - 4em);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      p {
        font-size: 0.8em;
        line-height: 20px;
        opacity: 0.7;
        &.nome {
          font-weight: 600;
        }
      }
    }
  }
  .info-bar {
    width: 100%;
    height: 2em;
    display: flex;
    .info-btn {
      min-width: 2em;
      width: max-content;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      svg {
        color: black;
        opacity: 0.7;
      }
    }
  }
`;

export const Infos = styled.div`
  width: 100%;
  height: calc(100%-5em);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .tag {
    width: 100%;
    height: 2.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 0.7em;
      margin: 0 10px !important;
      opacity: 0.7;
    }
  }
  .progress-bar {
    width: 100%;
    height: 0.4em;
    background-color: rgba(0, 0, 0, 0.1);
    .progress {
      width: 0%;
      height: 100%;
      background-color: rgba(0, 200, 100, 0.7);
    }
  }
`;

export const EditInfos = styled.div`
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  display: flex;
  overflow: hidden;

  .input-line {
    width: 100%;
    min-height: 2.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(250, 250, 250);
    p {
      font-size: 0.7em;
      margin: 0 10px !important;
      opacity: 0.9;
    }
    .input-content {
      display: flex;
      align-items: center;
    }
    input {
      width: 6em;
      height: 1em;
      margin-right: 1em !important;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      color: black;
      padding: 2px 0;
      padding-left: 5px;
    }
  }
  .btn-edit-div {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-edit {
      width: max-content;
      height: max-content;
      border-radius: 5px;
      background-color: rgba(25, 25, 25);
      color: white;
      cursor: pointer;
      &:hover {
        background-color: rgba(15, 15, 15);
      }
      h2 {
        font-size: 0.9em;
        padding: 5px 30px;
        user-select: none;
      }
    }
  }
`;
