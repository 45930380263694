import {
  Container,
  Logo,
  Btn,
  LogoutBtn,
  Header,
  Terminal,
  MiniMenu,
} from './styles';

import axios from 'axios';

import { MdDashboard } from 'react-icons/md';
import { CiLogout } from 'react-icons/ci';
import { IoCubeOutline } from 'react-icons/io5';
import { CiCalendarDate } from 'react-icons/ci';
import { MdOutlineUpdate } from 'react-icons/md';
import { HiReceiptTax } from 'react-icons/hi';
import {
  AiFillFolder,
  AiOutlineFundProjectionScreen,
  AiOutlineClose,
  AiOutlineArrowRight,
} from 'react-icons/ai';
import { BsChatLeftFill, BsFillPeopleFill } from 'react-icons/bs';
import { FiMonitor } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

import logo from '../../img/ontax.png';
import { useApp } from '../../hooks/app';
import { useState } from 'react';

const uri = process.env.REACT_APP_API_URI;

export default function Menu() {
  const [toggle, setToggle] = useState(false);
  const { status, setStatus } = useApp();
  const { Logout } = useAuth();

  function checkClass(str) {
    if (str === status) {
      return 'ativ';
    }
  }

  function toggleMenu() {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  }
  return toggle ? (
    <Container id="menu">
      <Logo>
        <img src={logo} />
      </Logo>
      <LogoutBtn className="toggle" onClick={toggleMenu}>
        <AiOutlineClose />
      </LogoutBtn>
      <Btn
        onClick={() => setStatus('tasks')}
        className={`${checkClass('tasks')}`}
      >
        <div className="content">
          <MdDashboard />
          <p>Dashboard</p>
        </div>
      </Btn>
      <Btn
        onClick={() => setStatus('files')}
        className={`${checkClass('files')}`}
      >
        <div className="content">
          <AiFillFolder />
          <p>Arquivos</p>
        </div>
      </Btn>
      <Btn onClick={() => setStatus('tax')} className={`${checkClass('tax')}`}>
        <div className="content">
          <HiReceiptTax />
          <p>Compensações</p>
        </div>
      </Btn>
      <Btn
        onClick={() => setStatus('projetos')}
        className={`${checkClass('projetos')}`}
      >
        <div className="content">
          <AiOutlineFundProjectionScreen />
          <p>Projetos</p>
        </div>
      </Btn>
      <Btn
        onClick={() => setStatus('clients')}
        className={`${checkClass('clients')}`}
        /* style={{ display: "none" }} */
      >
        <div className="content">
          <BsFillPeopleFill />
          <p>Clientes</p>
        </div>
      </Btn>
      <Btn
        style={{ display: 'none' }}
        onClick={() => setStatus('monitor')}
        className={`${checkClass('monitor')}`}
        /* style={{ display: "none" }} */
      >
        <div className="content">
          <FiMonitor />
          <p>Monitoramento</p>
        </div>
      </Btn>
      <Btn
        style={{ display: 'none' }}
        onClick={() => setStatus('suport')}
        className={`${checkClass('suport')}`}
      >
        <div className="content">
          <BsChatLeftFill />
          <p>Suporte</p>
        </div>
      </Btn>
      <Btn
        style={{ display: 'none' }}
        onClick={() => setStatus('progress')}
        className={`${checkClass('progress')}`}
      >
        <div className="content">
          <CiCalendarDate />
          <p>Desenvolvimento</p>
        </div>
      </Btn>
      <Btn className="logout" onClick={Logout}>
        <div className="content ">
          <CiLogout />
          <p>Desconectar</p>
        </div>
      </Btn>
    </Container>
  ) : (
    <MiniMenu>
      <Btn onClick={toggleMenu}>
        <AiOutlineArrowRight />
      </Btn>
      <Btn
        onClick={() => setStatus('tasks')}
        className={`${checkClass('tasks')}`}
      >
        <div className="content">
          <MdDashboard />
        </div>
      </Btn>
      <Btn
        onClick={() => setStatus('files')}
        className={`${checkClass('files')}`}
      >
        <div className="content">
          <AiFillFolder />
        </div>
      </Btn>
      <Btn onClick={() => setStatus('tax')} className={`${checkClass('tax')}`}>
        <div className="content">
          <HiReceiptTax />
        </div>
      </Btn>
      <Btn
        onClick={() => setStatus('projetos')}
        className={`${checkClass('projetos')}`}
      >
        <div className="content">
          <AiOutlineFundProjectionScreen />
        </div>
      </Btn>
      <Btn
        onClick={() => setStatus('clients')}
        className={`${checkClass('clients')}`}
        /* style={{ display: "none" }} */
      >
        <div className="content">
          <BsFillPeopleFill />
        </div>
      </Btn>
      <Btn
        style={{ display: 'none' }}
        onClick={() => setStatus('monitor')}
        className={`${checkClass('monitor')}`}
        /* style={{ display: "none" }} */
      >
        <div className="content">
          <FiMonitor />
        </div>
      </Btn>
      <Btn
        style={{ display: 'none' }}
        onClick={() => setStatus('suport')}
        className={`${checkClass('suport')}`}
      >
        <div className="content">
          <BsChatLeftFill />
        </div>
      </Btn>
      <Btn
        style={{ display: 'none' }}
        onClick={() => setStatus('progress')}
        className={`${checkClass('progress')}`}
      >
        <div className="content">
          <CiCalendarDate />
        </div>
      </Btn>
      <Btn
        style={{ display: 'none' }}
        onClick={() => setStatus('update')}
        className={`${checkClass('update')}`}
      >
        <div className="content">
          <MdOutlineUpdate />
        </div>
      </Btn>

      <Btn className="logout" onClick={Logout}>
        <CiLogout />
      </Btn>
    </MiniMenu>
  );
}
