import React, { useEffect, useState } from "react";
import { Container, Infos, Charts, Cards } from "./style";
import axios from "axios";

import { AiOutlineInfoCircle, AiOutlineIdcard } from "react-icons/ai";
import { IoCubeOutline, IoDocumentTextOutline } from "react-icons/io5";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { FaDatabase } from "react-icons/fa";

import { useUsers } from "../../../../../../hooks/users";
import { useProjetos } from "../../../../../../hooks/projetos";
import { useTools } from "../../../../../../hooks/tools";

import { IoIosArrowBack } from "react-icons/io";

export default function User() {
  const uri = process.env.REACT_APP_API_URI;

  const { users, setUsers, user, setUser, setUserContainer } = useUsers();
  const { projeto, projetos } = useProjetos();
  const { openTools } = useTools();
  const { setProjeto } = useProjetos();

  const [logs, setLogs] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [data, setData] = useState();
  const [cnpj, setCnpj] = useState();
  const [ano, setAno] = useState();
  const [projetoInfo, setProjetoInfo] = useState();
  const [projetoId, setProjetoId] = useState();
  const [charts, setCharts] = useState([]);

  function percent(num, array) {
    var total = 0;
    array.map((obj) => (total += obj.logs));
    var porcentagem = total / 100;

    var resultado = parseFloat(num / porcentagem).toFixed(2);

    return `${resultado}%`;
  }

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  useEffect(() => {}, [cnpj, ano, projeto]);

  useEffect(() => {
    setLogs([]);
    axios.get(`${uri}/adm/logs/${user._id}`).then((res) => {
      console.log("log", res.data);
      if (res.data !== null) {
        setLogs(res.data.logs.actions);
        setCharts(res.data.charts);
      }
    });
  }, []);

  useEffect(() => {
    if (user !== undefined && projetos.length !== 0) {
      const info = projetos.find((obj) => obj.data._id === user.projeto);

      setProjetoInfo(info);
      /* setProjetoId(projetoInfo._id); */
    }
  }, [user, projetoInfo, data]);

  if (user !== undefined) {
    return (
      <Container>
        <div className="close-btn" onClick={() => setUserContainer("users")}>
          <IoIosArrowBack />
        </div>
        {data !== undefined && (
          <Cards>
            {data.cards.map((obj) => (
              <div className="cards-box">
                <div className="title">
                  <p>{obj.tag}</p>
                  <AiOutlineInfoCircle />
                </div>
                <div className="cards-info">
                  <p>{obj.value.toLocaleString()}</p>
                </div>
              </div>
            ))}
          </Cards>
        )}
        <Charts>
          <div className="chart">
            <div className="chart-header">
              <p>Usuário</p>
            </div>
            {projetoInfo !== undefined && (
              <div className="user">
                <div className="header">
                  <div className="thumb">
                    <div
                      className="circle"
                      style={{ backgroundColor: ` ${projetoInfo.color}` }}
                    >
                      <p>{user.nome.slice(0, 1)}</p>
                    </div>
                  </div>
                  <div className="text">
                    <p>{user.nome}</p>
                    <p>{user.email}</p>
                  </div>
                </div>
                <div className="projects">
                  <div className="project-line">
                    <div
                      className="border-line"
                      style={{ backgroundColor: ` ${projetoInfo.color}` }}
                    ></div>
                    <div className="content">
                      <div className="names">
                        <p>{projetoInfo.data.nome}</p>
                        <p>{projetoInfo.data.cnpj}</p>
                      </div>
                      <div className="infos">
                        <div className="info">
                          <div className="ico">
                            <HiOutlineReceiptTax />
                          </div>
                          <div className="info-txt">
                            <h2>{projetoInfo.data.nfes}</h2>
                          </div>
                        </div>
                        <div className="info">
                          <div className="ico fa">
                            <FaDatabase />
                          </div>
                          <div className="info-txt">
                            <h2>{formatBytes(projetoInfo.data.size)}</h2>
                          </div>
                        </div>
                        <div className="info">
                          <div className="ico fa">
                            <IoDocumentTextOutline />
                          </div>
                          <div className="info-txt">
                            <h2>{projetoInfo.data.speds}</h2>
                          </div>
                        </div>
                        <div className="info">
                          <div className="ico fa">
                            <AiOutlineIdcard />
                          </div>
                          <div className="info-txt">
                            <h2>{projetoInfo.data.empresas}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="chart">
            <div className="chart-header">
              <p>Index</p>
            </div>
            <div className="index">
              {charts.length > 0 &&
                charts.map((obj) => (
                  <div className="line-index">
                    <div className="logo">
                      <IoCubeOutline style={{ color: obj.color }} />
                    </div>
                    <div className="index-name">
                      <p>{obj.nome}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="chart">
            <div className="chart-header">
              <p>Logs da semana</p>
            </div>

            <div className="log-chart">
              <div className="chart-container">
                {charts.map((obj) => (
                  <div className="log-day">
                    <div
                      className="chart-value"
                      style={{
                        backgroundColor: obj.color,
                        height: percent(obj.logs, charts),
                      }}
                    ></div>
                  </div>
                ))}
              </div>
              <div className="week-day">
                <div className="day">
                  <p>Dom.</p>
                </div>
                <div className="day">
                  <p>Seg.</p>
                </div>
                <div className="day">
                  <p>Ter.</p>
                </div>
                <div className="day">
                  <p>Qua.</p>
                </div>
                <div className="day">
                  <p>Qui.</p>
                </div>
                <div className="day">
                  <p>Sex.</p>
                </div>
                <div className="day">
                  <p>Sab.</p>
                </div>
              </div>
            </div>
          </div>
        </Charts>
        <Infos>
          <div className="header">
            <div className="item desc">
              <p>Descrição</p>
            </div>
            <div className="item path">
              <p>Caminho</p>
            </div>
            <div className="item method">
              <p>Método</p>
            </div>

            <div className="item">
              <p>Horário</p>
            </div>
          </div>
          <div className="logs">
            {logs.length > 0 &&
              logs.map((obj) => (
                <div className="line">
                  <div className="item desc">
                    {/* {users.find(
                        (usr) => usr._id === obj.userId && <p>{obj.nome}</p>
                      )} */}
                  </div>
                  <div className="item path">
                    <p>{obj.path}</p>
                  </div>

                  <div className="item method">
                    <p>{obj.method}</p>
                  </div>
                  <div className="item date">
                    <p>
                      {`${new Date(obj.createdAt).toLocaleDateString()} ${
                        new Date(obj.createdAt).toTimeString().split(" ")[0]
                      }`}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </Infos>
      </Container>
    );
  } else {
    return <Container />;
  }
}
