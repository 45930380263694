

import { useFiles } from "../../../../hooks/files";

import FilesComponent from "./components/files";
import ProjetosComponent from "./components/projetos";

const uri = process.env.REACT_APP_API_URI;

export default function Files() {
  const { filter } = useFiles();

  return <>{filter !== undefined ? <FilesComponent /> : <ProjetosComponent />}</>;
}
