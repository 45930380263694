import React, { useState, useEffect } from "react";
import { Container, Content } from "./style";


import { MdOutlineClose } from "react-icons/md";

import axios from "axios";
import { useTools } from "../../../hooks/tools";

export default function ShowScreen() {
  const [worker, setWorker] = useState();

  const { openTools } = useTools()

  function handleClose() {
    openTools("");
  }


  useEffect(() => {
    axios.get("https://ontax.app/adm/workers").then((res) => {
      setWorker(res.data[0]);
    });
  }, []);

  return (
    <Container>
      <Content>
        <img src={worker !== undefined && worker.telas[0]} />
        {
          <div className="btn" onClick={handleClose}>
            <MdOutlineClose />
          </div>
        }
      </Content>
    </Container>
  );
}
