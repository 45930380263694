import { Menu, Projetos } from "./styles";

import { useProjetos } from "../../../../hooks/projetos";
import { useTools } from "../../../../hooks/tools";
import { useUsers } from "../../../../hooks/users";

import { HiUsers } from "react-icons/hi";
import { AiFillProject } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";

import { HiOutlineReceiptTax } from "react-icons/hi";
import { FaDatabase } from "react-icons/fa";
import { AiOutlineIdcard, AiOutlineCrown } from "react-icons/ai";

import { IoDocumentTextOutline } from "react-icons/io5";

export default function ToolsMenu() {
  const { users, setUser } = useUsers();
  const { openTools } = useTools();
  const { projetos, setProjeto } = useProjetos();

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  return (
    <Menu>
      <Projetos>
        <div className="header">
          <div className="content">
            <HiUsers />
            <p>Usuários</p>
          </div>
          <div className="btn" onClick={() => openTools("new_user")}>
            <BsPlusLg />
          </div>
        </div>
        <div className="display">
          {users !== undefined &&
            users.map((user) =>
              user.google === undefined ? (
                <div
                  className={`projeto user`}
                  onClick={() => openTools("user", setUser(user))}
                >
                  <div
                    className="thumb slim"
                    style={{
                      backgroundColor: `${
                        projetos !== undefined &&
                        projetos.find(
                          (obj) => obj.data._id === user.projeto
                        ) !== undefined &&
                        projetos.find((obj) => obj.data._id === user.projeto)
                          .color
                      }`,
                    }}
                  ></div>
                  <div className="content slim">
                    <div className="text">
                      <h2>{user.nome}</h2>
                      <p>{user.email}</p>
                    </div>
                  </div>
                  {user.classe === process.env.REACT_APP_ADMIN && (
                    <div className="crown">
                      <AiOutlineCrown />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`projeto user`}
                  onClick={() => openTools("user", setUser(user))}
                >
                  <div
                    className="thumb slim"
                    style={{
                      backgroundColor: `${
                        projetos !== undefined &&
                        projetos.find(
                          (obj) => obj.data._id === user.projeto
                        ) !== undefined &&
                        projetos.find((obj) => obj.data._id === user.projeto)
                          .color
                      }`,
                    }}
                  ></div>
                  <div className="thumb">
                    <div
                      className="circle"
                      style={{
                        backgroundImage: `url(${user.google.imageUrl})`,
                      }}
                    ></div>
                  </div>

                  <div className="content slim">
                    <div className="text">
                      <h2>{user.google.name}</h2>
                      <p>{user.google.email}</p>
                    </div>
                  </div>

                  {user.classe === process.env.REACT_APP_ADMIN && (
                    <div className="crown">
                      <AiOutlineCrown />
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      </Projetos>
      <Projetos className="double">
        <div className="header">
          <div className="content">
            <AiFillProject />
            <p>Projetos</p>
          </div>
          <div className="btn" onClick={() => openTools("new_project")}>
            <BsPlusLg />
          </div>
        </div>
        <div className="display">
          {projetos !== undefined &&
            projetos
              .filter((item) => item.data.archive !== true)
              .map((projeto) => (
                <div
                  className="projeto"
                  onClick={() => openTools("projeto", setProjeto(projeto))}
                >
                  <div
                    className="thumb slim"
                    style={{ backgroundColor: projeto.color }}
                  ></div>
                  <div className="content slim">
                    <div className="text">
                      <h2>{projeto.data.nome}</h2>
                      <p>{projeto.data.cnpj}</p>
                    </div>
                    <div className="infos">
                      <div className="info">
                        <div className="ico">
                          <HiOutlineReceiptTax />
                        </div>
                        <div className="info-txt">
                          <h2>{projeto.data.nfes}</h2>
                        </div>
                      </div>
                      <div className="info">
                        <div className="ico fa">
                          <FaDatabase />
                        </div>
                        <div className="info-txt">
                          <h2>{formatBytes(projeto.data.size)}</h2>
                        </div>
                      </div>
                      <div className="info">
                        <div className="ico fa">
                          <IoDocumentTextOutline />
                        </div>
                        <div className="info-txt">
                          <h2>{projeto.data.speds}</h2>
                        </div>
                      </div>
                      <div className="info">
                        <div className="ico fa">
                          <AiOutlineIdcard />
                        </div>
                        <div className="info-txt">
                          <h2>{projeto.data.empresas}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </Projetos>
    </Menu>
  );
}
