import { useState, useEffect } from "react";
import { Container, Contatos, Chat } from "./styles";

import { HiOutlineSearch } from "react-icons/hi";
import { IoSend } from "react-icons/io5"

import axios from "axios";
import { toast } from "react-toastify";

const uri = process.env.REACT_APP_API_URI;

export default function Suporte() {
  return (
    <Container>
      <Contatos>
        <div className="header">
          <div className="thumb">
            <HiOutlineSearch />
          </div>
          <input></input>
        </div>
        <div className="display">
          <div className="contato">
            <div className="contato-thumb">
              <div className="circle"></div>
            </div>
            <div className="contato-info">
              <h2>Henrique Esteves</h2>
              <p>henrique@esteves.com</p>
            </div>
          </div>
        </div>
      </Contatos>
      <Chat>
        <div className="header">

        </div>
        <div className="chat">

        </div>
        <div className="message">
          <input placeholder="Escreva uma mensagem...">
          </input>
          <div className="btn">
            <IoSend />
          </div>
        </div>
      </Chat>
    </Container>
  );
}
