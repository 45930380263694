import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: overlay;
`;

export const Box = styled.div`
  height: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: overlay;
`;

export const Tasks = styled.div`
  width: 25em;
  height: 100%;

  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  .delete {
    height: 100%;
    width: 3em;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    svg {
      color: rgba(150, 0, 0);
    }
  }

  .infos {
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(15, 15, 15);
    color: white;

    .btn {
      width: calc(100% / 3);
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      svg {
        margin-left: 1em !important;
      }

      p {
        margin-left: 0.5em !important;
        font-size: 0.8em;

        &.info {
          font-weight: 600;
        }
      }
    }
  }

  .head {
    width: 100%;
    height: 2em;

    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background-color: white;
    display: flex;
    align-items: center;

    .text {
      width: calc(100% - 2em);
      display: flex;
      align-items: center;
      svg {
        margin-left: 1em !important;
        margin-right: 0.5em !important;
      }
      p {
        font-weight: 600;
      }
    }

    .terminal {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .tasks {
    width: 100%;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    height: calc(100% - 3em);
    align-items: center;
  }

  .cancel {
    width: 100%;
    height: 5em;
    background-color: rgba(253, 253, 253);
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: 10em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.7;
      cursor: pointer;
      transition: all 0.2s;

      p {
        font-size: 0.85em;
        opacity: 0.7;
      }

      &:hover {
        opacity: 1;
        border: 1px solid rgba(0, 200, 250, 0.3);
      }
    }
  }
`;

export const Task = styled.div`
  width: 95%;
  min-height: 10em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: rgba(248, 248, 248);
  margin-top: 0.5em !important;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .header {
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background-color: white;

    .thumb {
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.03);

      .circle {
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 100%;
        opacity: 0.7;
        h1 {
          font-size: 1em;
          color: white;
        }
      }
    }

    .content {
      width: calc(100% - 3.5em);
      display: flex;
      flex-direction: column;
      margin-left: 0.5em !important;
      cursor: pointer;
      h2 {
        font-size: 0.8em;
        font-weight: 500;
      }

      p {
        font-size: 0.75em;
        opacity: 0.8;
        line-height: 13px;
      }
    }
  }

  .display {
    .timer {
      width: 3.5em;
      height: 1em;
      display: flex;
      position: fixed;
      background-color: white;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.03);
      p {
        font-size: 0.8em;
      }
    }

    height: calc(100% - 3em);
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    overflow: overlay;

    .task-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: max-content;

      &.doing {
        opacity: 1;
      }

      &.todo {
        opacity: 0.5;
      }
    }

    .task {
      width: 100%;
      height: 2em;
      display: flex;
      background-color: rgba(255, 255, 255);
      margin-top: 1px !important;

      .thumb {
        width: 2.5em;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid rgba(0, 0, 0, 0.03);
        justify-content: center;

        svg.green {
          color: rgba(0, 200, 100);
        }
      }

      .content {
        width: calc(100% - 2em);
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          margin-left: 1em !important;
          font-size: 0.7em;
          font-weight: 500;
        }
        p {
          margin-left: 1em !important;
          margin-right: 1em !important;
          font-size: 0.7em;
          opacity: 0.6;
          line-height: 20px;
        }
      }
    }

    .progress {
      width: 100%;
      height: 0.3em;
      display: flex;
      background-color: rgba(0, 0, 0, 0.1);

      .load-bar {
        width: 0%;
        background-color: rgba(0, 200, 100);
        transition: all 0.2s;
      }
    }

    .logs {
      width: 100%;
      height: max-content;
      max-height: 10em;
      display: flex;
      flex-direction: column;
      overflow: overlay;

      .log-container {
        width: 100%;
        min-height: max-content;
        display: flex;
        flex-direction: column;
        margin-top: 1px !important;
      }
      .log {
        width: 100%;
        height: 2em;
        background-color: rgba(252, 252, 252);

        display: flex;
        .thumb {
          width: 2em;
          height: 2em;
          border-right: 1px solid rgba(0, 0, 0, 0.03);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
        }

        .text {
          width: calc(100% - 2em);
          display: flex;
          align-items: center;

          p {
            font-size: 0.8em;
            opacity: 0.6;
            margin-left: 1em !important;
          }
        }
      }
    }
  }
`;
