import styled from "styled-components";


export const Menu = styled.div`
  width: 20em;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(248, 248, 248);
  right: 0;

`;

export const Projetos = styled.div`
  width: 100%;
  height: calc(100% / 3);
  display: flex;
  flex-direction: column;
  overflow: overlay;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  &.double {
    height: calc(100% / 3 * 2);
  }

  .header {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    background-color: rgba(15, 15, 15);
    color: white;
    border-radius: 5px 0 0px 5px;

    .content {
      width: calc(100% - 2em);
      display: flex;
      align-items: center;
    }

    .btn {
      width: 3em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    p {
      font-size: 0.8em;
      font-weight: 600;
      margin-left: 0.5em !important;
    }

    svg {
      margin-left: 1em !important;
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: column;
    overflow: overlay;
    background-color: rgba(250, 250, 250, 1);

    .projeto {
      width: 100%;
      height: 5em;
      min-height: 5em;
      display: flex;
      background-color: white;
      margin-top: 2px !important;
      padding: 0 !important;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.03);
      opacity: 0.7;
      cursor: pointer;

      &.user {
        height: 4em;
        min-height: 4em;

        .thumb {
          height: 4em;
        }
      }

      &:hover {
        opacity: 1;
      }

      .crown {
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;

        svg {
          color: rgba(0, 200, 50, 1);
        }
      }

      .thumb {
        width: 5em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        opacity: 1;

        &.slim {
          width: 0.3em;
          background-color: black;
          opacity: 0.6;
        }

        .circle {
          width: 2.5em;
          height: 2.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-position: center;
          background-size: cover;
        }
      }

      .content {
        width: calc(100% - 5em);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .text {
          height: calc(100% - 1.5em);
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 0.5em !important;
        }
        .infos {
          width: 100%;
          height: 1.5em;
          border-top: 1px solid rgba(0, 0, 0, 0.03);
          display: flex;

          .info {
            width: max-content;
            display: flex;
            border-right: 1px solid rgba(0, 0, 0, 0.03);

            .ico {
              width: 1.5em;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              &.fa {
                opacity: 0.5;
                font-size: 0.8em;
              }

              svg {
                opacity: 0.5;
              }
            }

            .info-txt {
              width: max-content;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              h2 {
                margin: 0 5px !important;
                margin-right: 10px !important;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                opacity: 0.5;
              }
            }
          }
        }

        &.slim {
          width: calc(100% - 0.3em);
        }

        h2 {
          font-size: 0.8em;
        }
        p {
          font-size: 0.7em;
          opacity: 0.8;
          transform: translateY(-0.4em);
        }
      }
    }
  }
`;