import { Container, Header, Content } from "./styles";

import { MdClose } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { AiFillLock } from "react-icons/ai";
import { useTools } from "../../../hooks/tools";
import { useEffect, useState } from "react";
import { useUsers } from "../../../hooks/users";
import alerta from "../../../utils/alerta";

export default function NewUser() {
  const { openTools } = useTools();
  const [classe, setClasse] = useState("user");
  const [subclass, setSubclass] = useState("free");
  const { newUser } = useUsers();

  function handleClose() {
    openTools("new_user");
  }

  function checkClass(str) {
    if (str === classe) {
      return "ativ";
    }
  }

  function checkSubclass(str) {
    if (str === subclass) {
      return "ativ";
    }
  }

  function handleNewUser() {
    var email = document.getElementById("new-user-email");
    var nome = document.getElementById("new-user-nome");
    var senha = document.getElementById("new-user-senha");

    var check = true;

    if (email.value === "") {
      alerta("Preencha o email do usuário", false, "err");
      email.classList.add("red");
      check = false;
    }

    if (senha.value === "") {
      alerta("Defina uma senha para usuário", false, "err");
      senha.classList.add("red");
      check = false;
    }

    if (nome.value === "") {
      alerta("Preencha o nome do usuário", false, "err");
      nome.classList.add("red");
      check = false;
    }

    if (check) {
      email = email.value;
      senha = senha.value;
      nome = nome.value;

      var new_user = {
        email,
        senha,
        nome,
        classe,
        subclass
      };

      function reset() {
        email = "";
        senha = "";
        nome = "";
        handleClose();
      }

      newUser(new_user, reset)
    }
  }


  function removeErr(ide) {
    document.getElementById(ide).classList.remove("red");
  }

  return (
    <Container>
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="content">
          <p>Novo usuário</p>
        </div>
      </Header>
      <Content>
        <div className="box">
          <div className="thumb">
            <IoIosPerson />
          </div>
          <input
            onClick={() => removeErr("new-user-nome")}
            placeholder="Nome"
            id="new-user-nome"
          />
        </div>
        <div className="box">
          <div className="thumb">
            <MdOutlineAlternateEmail />
          </div>
          <input
            onClick={() => removeErr("new-user-email")}
            placeholder="Email"
            id="new-user-email"
          />
        </div>
        <div className="box">
          <div className="thumb">
            <AiFillLock />
          </div>
          <input
            onClick={() => removeErr("new-user-senha")}
            placeholder="Senha"
            id="new-user-senha"
          />
        </div>

        <div className="box class">
          <div
            onClick={() => setClasse("user")}
            className={`class-btn ${checkClass("user")}`}
          >
            <p>CLIENTE</p>
          </div>
          <div
            onClick={() => setClasse("admin")}
            className={`class-btn ${checkClass("admin")}`}
          >
            <p>ADMINISTRADOR</p>
          </div>
        </div>

        {classe === "user" && (
          <div className="box class">
            <div
              onClick={() => setSubclass("free")}
              className={`class-btn ${checkSubclass("free")}`}
            >
              <p>FREE</p>
            </div>
            <div
              onClick={() => setSubclass("basic")}
              className={`class-btn ${checkSubclass("basic")}`}
            >
              <p>BASIC</p>
            </div>
            <div
              onClick={() => setSubclass("pro")}
              className={`class-btn ${checkSubclass("pro")}`}
            >
              <p>PRO</p>
            </div>
          </div>
        )}

        <div className="btn-container">
          <div className="btn" onClick={handleNewUser}>
            <h2>ENVIAR</h2>
          </div>
        </div>
      </Content>
    </Container>
  );
}
