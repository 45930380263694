import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

const FilesContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function FilesProvider({ children }) {
  const [files, setFiles] = useState([]);
  const [filter, setFilter] = useState();
  const [projetos, setProjetos] = useState([]);


  function atualizeFiles() {
    axios.get(`${uri}/transfer`).then((res) => {
      var result = res.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

      if (filter !== undefined) {
        setFiles(result.filter((obj) => obj.projeto === filter));
      } else {
        setFiles(result);
      }
      setProjetos(res.data.infos);
    });
  }

  useEffect(() => {
    atualizeFiles();
  }, [filter]);



  return (
    <FilesContext.Provider
      value={{
        atualizeFiles,
        files,
        filter,
        setFilter,
        projetos,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
}

export function useFiles() {
  const context = useContext(FilesContext);
  const { files, atualizeFiles, filter, setFilter, projetos } = context;
  return { files, atualizeFiles, filter, setFilter, projetos };
}
