import { Container, Item } from "../styles";

import axios from "axios";

import { FaDatabase } from "react-icons/fa";
import { AiTwotoneCalendar } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import { IoIosArrowRoundBack, IoMdTrash } from "react-icons/io";
import { useFiles } from "../../../../../hooks/files";

import alerta from "../../../../../utils/alerta";

import FileDownload from "js-file-download";
import { useState } from "react";
import { useEffect } from "react";
import { useProjetos } from "../../../../../hooks/projetos";

const uri = process.env.REACT_APP_API_URI;

export default function FilesComponent() {
  const { files, atualizeFiles, setFilter } = useFiles();
  const {projetos: projs} = useProjetos()


  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  function downloadFile(file) {
    var projeto = file.projeto;
    document.getElementById(`download-${files.indexOf(file)}`).style.display =
      "flex";

    axios
      .post(
        `${uri}/transfer/download/${projeto}`,
        { file: file.file },
        {
          responseType: "blob",
          onDownloadProgress: (e) => {
            var progress = parseInt(Math.round((e.loaded * 100) / e.total));
            document.getElementById(
              `${`prog-${files.indexOf(file)}`}`
            ).style.width = `${progress}%`;
          },
        }
      )
      .then((res) => {
        FileDownload(res.data, file.file);

        document.getElementById(
          `download-${files.indexOf(file)}`
        ).style.display = "none";

        document.getElementById(
          `${`prog-${files.indexOf(file)}`}`
        ).style.width = "0%";
      });
  }

  function deleteFile(file) {
    function start() {
      axios.post(`${uri}/transfer/delete`, { file }).then((res) => {
        alerta(res.data.msg, false, "success");
        atualizeFiles();
      });
    }

    alerta(
      "Tem certeza que deseja exclur esta remessa do banco de dados?",
      true,
      "err",
      start
    );
  }

  return (
    <Container>
      <div className="top-head">
        <div className="btn" onClick={() => setFilter()}>
          <IoIosArrowRoundBack />
        </div>
      </div>
      <div className="content">
        {files.length !== 0 &&
          files.map((obj) => (
            <Item>
              <div className="head">
                <div className="thumb">
                  <div
                    className="circle"
                    style={{
                      backgroundColor: projs.find(
                        (proj) => proj.data._id === obj.projeto
                      ).color,
                    }}
                  >
                    <h2>{obj.nome.slice(0, 1)}</h2>
                  </div>
                </div>
                <div className="text">
                  <div className="content">
                    <h3>{obj.nome}</h3>
                    <p>{obj.email}</p>
                  </div>
                </div>
              </div>

              <div className="infos-container">
                <div className="box">
                  <div className="tag">
                    <p>Titulo</p>
                  </div>
                  <div className="text">
                    <p>{obj.titulo}</p>
                  </div>
                </div>
                <div className="box">
                  <div className="tag">
                    <p>Descrição</p>
                  </div>
                  <div className="text">
                    <p>{obj.desc}</p>
                  </div>
                </div>
              </div>
              <div className="download" id={`download-${files.indexOf(obj)}`}>
                <div
                  className="progress"
                  id={`prog-${files.indexOf(obj)}`}
                ></div>
              </div>
              <div className="infos">
                <div className="tools">
                  <div className="tool">
                    <FaDatabase />
                    <p>{formatBytes(obj.size)}</p>
                  </div>
                  <div className="tool">
                    <AiTwotoneCalendar />
                    <p>{new Date(obj.created_at).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="tools">
                  <div className="btn" onClick={() => downloadFile(obj)}>
                    <HiDownload color="rgba(0, 100, 200, 1)" />
                  </div>
                  <div className="btn" onClick={() => deleteFile(obj._id)}>
                    <IoMdTrash color="rgba(150, 0, 0, 1)" />
                  </div>
                </div>
              </div>
            </Item>
          ))}
      </div>
    </Container>
  );
}
