import { useState, useEffect } from "react";
import axios from "axios";

import { Container, Block, Header, Display, Workers } from "./styles";

import { FaTerminal, FaServer } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useTools } from "../../../hooks/tools";

import ContentLoader from "react-content-loader";

const uri = process.env.REACT_APP_API_URI;

export default function Terminal() {
  const [logs, setLogs] = useState([]);
  const [errors, setErr] = useState([]);
  const { openTools } = useTools();
  const [status, setStatus] = useState("WORKER1");
  const [atualize, setAtualize] = useState(new Date().getTime());

  const timer = (ms) => new Promise((res) => setTimeout(res, ms));
  async function getLogs() {
    axios
      .post(`${uri}/admin/logs`, { status, type: "out" })
      .then(async (res) => {
        setLogs(res.data);

        axios
          .post(`${uri}/admin/logs`, { status, type: "error" })
          .then(async (res) => {
            setErr(res.data);
            await timer(3000);
            setAtualize(new Date());
          });
      });
  }

  useEffect(() => {
    (async () => {
      setLogs([]);
      await timer(5000);
    })();
  }, [status]);

  useEffect(() => {
    getLogs();
  }, [atualize]);

  function checkClass(str) {
    if (status.includes(str)) {
      return "ativ";
    }
  }

  function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function LoadPanel() {
    function LoadItem() {
      return (
        <div className="line white">
          <ContentLoader
            style={{
              transform: "translateX(.5em)",
              opacity: ".1",
            }}
            viewBox="100 0 3000 90"
          >
            <rect
              x="80"
              y="20"
              rx="3"
              ry="3"
              width={random(1000, 2500)}
              height="40"
            />
          </ContentLoader>
        </div>
      );
    }
    var x = 0;
    var result = [];

    while (x !== 16) {
      result.push(x);
      x++;
    }

    return (
      <div className="line-disp">
        {result.map((obj) => (
          <LoadItem key={result.indexOf(obj)} />
        ))}
      </div>
    );
  }

  return (
    <Container>
      <Block>
        <Header>
          <div className="text">
            <div
              onClick={() => setStatus("WORKER")}
              className={`btn ${checkClass("WORKER1")}`}
            >
              <FaTerminal />
              <p>WORKER</p>
            </div>
            <div
              onClick={() => setStatus("SERVER")}
              className={`btn ${checkClass("SERVER")}`}
            >
              <FaServer />
              <p>SERVER</p>
            </div>
          </div>
          <div className="close" onClick={() => openTools("")}>
            <MdClose />
          </div>
        </Header>
        {status.includes("WORKER") && (
          <Workers>
            <div
              onClick={() => setStatus("WORKER1")}
              className={`btn ${checkClass("WORKER1")}`}
            >
              <p>WORKER 1</p>
            </div>
            <div
              onClick={() => setStatus("WORKER2")}
              className={`btn ${checkClass("WORKER2")}`}
            >
              <p>WORKER 2</p>
            </div>
            <div
              onClick={() => setStatus("WORKER3")}
              className={`btn ${checkClass("WORKER3")}`}
            >
              <p>WORKER 3</p>
            </div>
            <div
              onClick={() => setStatus("WORKER4")}
              className={`btn ${checkClass("WORKER4")}`}
            >
              <p>WORKER 4</p>
            </div>
          </Workers>
        )}
        <Display className={`${status.includes("WORKER") && "ativ"}`}>
          {logs.length !== 0 ? (
            logs.map((obj) => (
              <div className="line">
                <p className="success">{status} | </p>
                <p>{obj}</p>
              </div>
            ))
          ) : (
            <LoadPanel />
          )}

          {errors.length !== 0 ? (
            errors.map((obj) => (
              <div className="line">
                <p className="err">{status} | </p>
                <p>{obj}</p>
              </div>
            ))
          ) : (
            <LoadPanel />
          )}
        </Display>
      </Block>
    </Container>
  );
}
