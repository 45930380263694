import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .content {
    overflow: overlay;
    width: 97%;
    height: max-content;
    max-height: calc(100% - 2em);
    display: flex;
    flex-wrap: wrap;

    &.top {
      margin-top: 2em;
    }
  }

  .top-head {
    width: 100%;
    height: 2em;
    display: flex;

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      opacity: .8;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      svg {
        font-size: 1.5em;
      }
    }
  }
`;

export const Item = styled.div`
  min-width: 21em;
  width: 21em;
  height: 25em;
  background-color: rgba(250, 250, 250);
  display: flex;
  flex-direction: column;
  margin: 1em !important;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .head {
    width: 100%;
    height: 5em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: white;
    .thumb {
      width: 5em;
      height: 5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.05);

      .circle {
        width: 3em;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 100%;

        h2 {
          color: white;
        }
      }
    }

    .text {
      width: calc(100% - 5em);
      height: 100%;
      display: flex;
      align-items: center;
      .content {
        width: max-content;
        height: max-content;
        display: flex;
        flex-direction: column;
        margin-left: 1em !important;

        h3 {
          font-size: 0.9em;
        }

        p {
          font-size: 0.7em;
          line-height: 15px;
        }
      }
    }
  }

  .infos-container {
    width: 100%;
    height: calc(100% - 7.5em);
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .box {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;

      .tag {
        width: 100%;
        height: 1.5em;
        background-color: rgba(245, 245, 245, 1);
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        p {
          margin-left: 1em !important;
          font-size: 0.8em;
          font-weight: 600;
          opacity: 0.7;
        }
      }

      .text {
        width: 100%;
        min-height: 2em;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          width: 95%;
          height: max-content;
          opacity: 0.8;
          font-size: 0.9em;
        }
      }
    }
  }

  .infos {
    width: 100%;
    height: 2.5em;
    display: flex;
    background-color: white;
    justify-content: space-between;

    .tools {
      width: max-content;
      height: 100%;
      display: flex;
      .tool {
        width: max-content;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;

        svg {
          font-size: 0.8em;
          opacity: 0.5;
          margin-left: 1em !important;
        }

        p {
          margin-left: 0.5em !important;
          font-size: 0.9em;
          margin-right: 1em !important;
        }
      }

      .btn {
        width: 2.5em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .download {
    width: 100%;
    height: 0.5em;
    display: flex;
    background-color: rgba(0, 0, 0, 0.05);
    display: none;

    .progress {
      width: 0%;
      height: 100%;
      background-color: rgba(0, 200, 100);
    }
  }
`;

export const Projeto = styled.div`
  width: calc(100% / 5);
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 0.5em;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  .head {
    width: 100%;
    height: 4.5em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;

    .thumb {
      width: 4.5em;
      height: 4.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      .circle {
        width: 3em;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        opacity: 0.8;
        h1 {
          font-size: 1.6em;
          color: white;
          font-weight: 500;
        }
      }
    }

    .content-head {
      width: calc(100% - 5em);
      height: 60%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.5em;

      p {
        line-height: 20px;
        &.cnpj {
          font-size: 0.8em;
          opacity: 0.7;
        }
      }
    }
  }

  .infos {
    width: 100%;
    height: 2em;
    display: flex;
    .info {
      width: max-content;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0 10px;

      svg {
        margin-right: .5em;
        opacity: .7;
      }
      p {
        font-size: 0.8em;
      }
    }
  }
`;
