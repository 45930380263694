import styled from "styled-components";

export const Container = styled.div`
  width: 25em;
  height:max-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(248, 248, 248);
  transform: translateY(-2em);
  border-radius: 10px;
  overflow: hidden;

`;

export const Header = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  background-color: white;

  .close {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    border-right: 1px solid rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }

  .content {
    width: 100%;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    

    p {
      font-weight: 600;
      opacity: .7;
      transform: translateX(-.5em);
    }
  }
`

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 2.5em);
  display: flex;
  flex-direction: column;

  .head {
    width: 100%;
    height: 1.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(240, 240, 240);
    p {
      font-size: 0.8em;
      font-weight: 600;
      margin-left: 0.5em !important;
      opacity: 0.5;
    }
  }

  .box {
    width: 100%;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);

    .class-btn {
      width: max-content;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1em !important;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      background-color: white;
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.ativ {
        border: 1px solid rgba(0, 200, 250, 0.3);
        opacity: 1;
      }
      p {
        padding: 0 25px !important;
        font-size: 0.8em;
        user-select: none;
      }
    }

    .thumb {
      width: 3em;
      height: 3em;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-right: none;

      svg {
        opacity: 0.5;
      }
    }

    input {
      width: 78%;
      height: 3em;
      background-color: white;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding-left: 1em;
      font-size: 1em;

      &.red {
        border: 1px solid rgba(250, 0, 0, 0.3);
      }
    }
  }

  .btn-container {
    width: 100%;
    height: 5em;
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    align-items: center;
    justify-content: center;

    .btn {
      opacity: 0.9;
      width: max-content;
      height: max-content;
      background-color: black;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      h2 {
        font-size: 1em;
        padding: 7px 40px;
        opacity:1;
      }
    }
  }

  .display {
    width: 100%;
    height: 8em;
    display: flex;
  }
`;
