import { Container, Card, Header } from "./styles";
import { TbTrash } from "react-icons/tb";
import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { useTools } from "../../../../../../hooks/tools";

export default function Dashboard() {
  const { openTools } = useTools();
  return (
    <Container>
      <Header>
        <div className="btn" onClick={() => openTools('update')}>
          <AiOutlinePlus />
          <p>NOVO UPDATE</p>
        </div>
      </Header>
      <Card>
        <div className="thumb"></div>
        <div className="content">
          <div className="title">
            <h2>Dashboad de updates</h2>
          </div>
          <div className="desc">
            <p>
              There are many variations of passages of Lorem Ipsum available,
            </p>
          </div>
        </div>

        <div className="infos">
          <div className="btn">
            <AiOutlineEdit />
          </div>
          <div className="btn">
            <TbTrash />
          </div>
        </div>
      </Card>
    </Container>
  );
}
