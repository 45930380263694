import { BsRobot } from "react-icons/bs";
import { Container, Box, Tasks, Task } from "./styles";
import { toast } from "react-toastify";
import axios from "axios";
import { AiOutlineRobot, AiOutlinePlus } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";

import { useTasks } from "../../../../../../hooks/tasks";
import { useProjetos } from "../../../../../../hooks/projetos";
import percent from "../../../../../../utils/percent";
import { useTools } from "../../../../../../hooks/tools";
import alerta from "../../../../../../utils/alerta";


const uri = process.env.REACT_APP_API_URI;

export default function WorkerComponent() {
  const { openTools } = useTools();
  const { workers, setWorkers } = useTasks();
  const { projetos } = useProjetos();

  function copyId(obj) {
    navigator.clipboard.writeText(obj._id);
    toast.success("Id do Worker copiado!");
  }

  function handleDelete(ide) {
    function trigger() {
      axios.post(`${uri}/adm/worker-delete/${ide}`).then((res) => {
        axios.get(`${uri}/adm/workers`).then(async (res) => {
          setWorkers(res.data);
        });
        toast.success(res.data.msg);
      });
    }
    alerta(
      "Tem certeza que quer deletar o Worker selecionado?",
      true,
      "err",
      trigger
    );
  }
  function RenderWorker(obj) {
    const projeto = projetos.find((proj) => proj.data._id === obj.projeto);

    return (
      <Task>
        <div className="header">
          <div className="thumb">
            <div className="circle" style={{ backgroundColor: projeto.color }}>
              <h1>{projeto.data.nome.slice(0, 1)}</h1>
            </div>
          </div>
          <div className="content" onClick={() => copyId(obj)}>
            <h2>{projeto.data.nome}</h2>
            <p>{projeto.data.cnpj}</p>
          </div>
          <div
            className="delete"
            onClick={() => handleDelete(projeto.data._id)}
          >
            <IoTrashOutline />
          </div>
        </div>
        <div className="display">
          <div className={`task-container`}>
            <div className="task">
              <div className="thumb"></div>
              <div className="content">
                <h2>Notas totais</h2>
                <p>{obj.infos.totais}</p>
              </div>
            </div>
          </div>
      
          <div className={`task-container`}>
            <div className="task">
              <div className="thumb"></div>
              <div className="content">
                <h2>Notas baixadas</h2>
                <p>{obj.infos.progress}</p>
              </div>
            </div>

            <div className="progress">
              <div
                className="load-bar"
                style={{
                  width: `${percent(
                    parseInt(obj.infos.totais),
                    parseInt(obj.infos.progress)
                  )}%`,
                }}
              ></div>
            </div>
          </div>
          <div className={`task-container`}>
            <div className="task">
              <div className="thumb"></div>
              <div className="content">
                <h2>Notas para baixar</h2>
                <p>{obj.infos.totais - obj.infos.progress}</p>
              </div>
            </div>

            <div className="progress">
              <div
                className="load-bar"
                style={{
                  width: `${percent(
                    parseInt(obj.infos.totais),
                    parseInt(obj.infos.totais - obj.infos.progress)
                  )}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </Task>
    );
  }

  return (
    <Box>
      <Tasks>
        <div className="head">
          <div className="text">
            <AiOutlineRobot />
            <p>Workers</p>
          </div>
          <div className="terminal" onClick={() => openTools("new_worker")}>
            <AiOutlinePlus />
          </div>
        </div>
        <div className="tasks">
          {projetos.length > 0 &&
            workers.length > 0 &&
            workers.map((obj) => RenderWorker(obj))}
        </div>
      </Tasks>
    </Box>
  );
}
