import { Container, Header, Content, Infos } from "./styles";

import { MdClose } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";
import { HiUsers, HiOfficeBuilding } from "react-icons/hi";
import { BiPlus } from "react-icons/bi";
import { HiTrash } from "react-icons/hi";
import { MdOutlineAlternateEmail } from "react-icons/md";

import { useTools } from "../../../hooks/tools";
import { BsArchiveFill, BsTrash } from "react-icons/bs";
import { BiWindowOpen } from "react-icons/bi";
import { useEffect, useState } from "react";
import axios from "axios";
import alerta from "../../../utils/alerta";
import { useUsers } from "../../../hooks/users";
import ContentLoader from "react-content-loader";
import { useProjetos } from "../../../hooks/projetos";

const uri = process.env.REACT_APP_API_URI;

export default function Projeto() {
  const { openTools } = useTools();
  const { users, setUsers, getUsers, user } = useUsers();
  const [new_users, setNewUsers] = useState([]);
  const [search, setSearch] = useState("");

  const {
    novoProjeto,
    projeto,
    deleteProjeto,
    projetos,
    editProjeto,
    setProjeto,
  } = useProjetos();

  useEffect(() => {
    if (projeto !== undefined) {
      setProjeto(projeto);
    }
  }, []);

  useEffect(() => {
    if (projeto !== undefined && projetos.length > 0) {
      setNewUsers([]);
      setNewUsers(
        projeto.data.users.map((obj) => users.find((user) => user._id === obj))
      );

      var nome = document.getElementById("project-nome");
      var cnpj = document.getElementById("project-cnpj");

      nome.value = projeto.data.nome;
      cnpj.value = projeto.data.cnpj;
    }
  }, [projeto]);

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  function handleClose() {
    openTools("");
  }

  function handleDeleteProjeto() {
    function start() {
      document.getElementById(
        "mini-text"
      ).innerHTML = `Excluindo projeto ${projeto.data.nome}`;
      document.getElementById("mini-alert").classList.add("ativ");
      document.getElementById("mini-load").style.display = "flex";
      document.getElementById("mini-check").style.display = "none";
    }

    function startDelete() {
      deleteProjeto(localStorage.getItem("user_id"), projeto.data._id, start);
      handleClose();
      document.getElementById("alert").style.display = "none";
      start();
    }

    alerta(
      "Tem certeza que deseja excluir esse projeto do banco de dados?",
      true,
      "err",
      startDelete
    );
  }

  function findUser() {
    var email = document.getElementById("project-email").value;

    axios
      .post(`${uri}/tools/user`, { email })
      .then((res) => {
        if (res.status === 200) {
          var new_users = users.concat(res.data);
          setUsers(new_users);
        }
      })
      .catch((e) => {
        alerta("Usuário não encontrado", false, "err");
      });
  }

  function LoadPanel() {
    function LoadItem() {
      return (
        <div className="user">
          <div className="thumb">
            <ContentLoader
              viewBox="0 0 380 300"
              style={{ transform: `translateY(.1em)` }}
            >
              {/* Only SVG shapes */}
              <rect x="80" y="30" rx="5" ry="5" width="200" height="200" />
            </ContentLoader>
          </div>
          <div className="text">
            <ContentLoader
              viewBox="0 0 3500 600"
              style={{ transform: `translateY(.1em)` }}
            >
              {/* Only SVG shapes */}
              <rect x="0" y="90" rx="5" ry="5" width="3000" height="160" />
              <rect x="0" y="360" rx="5" ry="5" width="1000" height="80" />
            </ContentLoader>
          </div>
        </div>
      );
    }
    var x = 0;
    var result = [];

    while (x !== 12) {
      result.push(x);
      x++;
    }

    return (
      <>
        {result.map((obj) => (
          <LoadItem key={result.indexOf(obj)} />
        ))}
      </>
    );
  }

  function addUser(obj) {
    setNewUsers(new_users.concat(obj));
    setSearch("");
    document.getElementById("project-email").value = "";
  }

  function removeUser(obj) {
    setNewUsers(new_users.filter((item) => item !== obj));
  }

  function handleArchiveProjeto() {
    var nome = document.getElementById("project-nome");
    var cnpj = document.getElementById("project-cnpj");
    var email = document.getElementById("project-email");

    var ide = projeto.data._id;
    var usuarios = [];
    if (new_users.length > 0) {
      for (var i in new_users) {
        var user = new_users[i]._id;
        usuarios.push(user);
      }
    }

    var check = true;
    if (nome.value === "") {
      alerta("Insira o nome do projeto", false, "err");
      nome.classList.add("red");
      check = false;
    }

    if (cnpj.value === "") {
      alerta("Insira o CNPJ da empresa", false, "err");
      cnpj.classList.add("red");
      check = false;
    }

    if (new_users.length === 0) {
      alerta("Adicione usuários para o projeto", false, "err");
      email.classList.add("red");
      check = false;
    }

    if (check === true) {
      nome = nome.value;
      cnpj = cnpj.value;
      var new_project = {
        nome,
        cnpj,
        users: usuarios,
        projeto: ide,
        archive: projeto.data.archive,
      };

      function reset() {
        nome = "";
        cnpj = "";
        setNewUsers([]);
        handleClose();
        setUsers([]);
        getUsers();
      }

      editProjeto(new_project, reset);
    }
  }
  function handleEditProjeto() {
    var nome = document.getElementById("project-nome");
    var cnpj = document.getElementById("project-cnpj");
    var email = document.getElementById("project-email");

    var ide = projeto.data._id;
    var usuarios = [];
    if (new_users.length > 0) {
      for (var i in new_users) {
        var user = new_users[i]._id;
        usuarios.push(user);
      }
    }

    var check = true;
    if (nome.value === "") {
      alerta("Insira o nome do projeto", false, "err");
      nome.classList.add("red");
      check = false;
    }

    if (cnpj.value === "") {
      alerta("Insira o CNPJ da empresa", false, "err");
      cnpj.classList.add("red");
      check = false;
    }

    if (new_users.length === 0) {
      alerta("Adicione usuários para o projeto", false, "err");
      email.classList.add("red");
      check = false;
    }

    if (check === true) {
      nome = nome.value;
      cnpj = cnpj.value;
      var new_project = {
        nome,
        cnpj,
        users: usuarios,
        projeto: ide,
      };

      function reset() {
        nome = "";
        cnpj = "";
        setNewUsers([]);
        handleClose();
        setUsers([]);
        getUsers();
      }

      editProjeto(new_project, reset);
    }
  }

  async function setProj() {
    axios
      .post(`${uri}/user/${user}`, { projeto: projeto.data._id })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = process.env.REACT_APP_URI;
        }
      });
  }

  return (
    <Container>
      {search !== "" && (
        <div className="users_box">
          {users.length !== 0 &&
            search !== "" &&
            users
              .filter(
                (obj) =>
                  obj.email.toLowerCase().includes(search.toLowerCase()) ||
                  obj.nome.toLowerCase().includes(search.toLowerCase())
              )
              .map((obj) => (
                <>
                  <div className="user select" onClick={() => addUser(obj)}>
                    <div className="thumb">
                      <div className="circle">
                        <p>{obj.nome.slice(0, 1)}</p>
                      </div>
                    </div>
                    <div className="text">
                      <p>{obj.nome}</p>
                      <p className="email">{obj.email}</p>
                    </div>
                  </div>
                  <LoadPanel />
                </>
              ))}
        </div>
      )}
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="content">
          <p>Editando Projeto</p>
        </div>
        <div className="close view" onClick={setProj}>
          <BiWindowOpen />
        </div>
        <div className="close archive" onClick={handleArchiveProjeto}>
          {projeto.data.archive === true ? (
            <BsArchiveFill color="rgba(0,100,150,0.8)" />
          ) : (
            <BsArchiveFill />
          )}
        </div>
        <div className="close delete" onClick={handleDeleteProjeto}>
          <BsTrash />
        </div>
      </Header>

      {projeto !== undefined && (
        <Content>
          <div className="box">
            <div className="thumb">
              <IoIosPerson />
            </div>
            <input placeholder="Nome do projeto" id="project-nome" />
          </div>
          <div className="box">
            <div className="thumb">
              <HiOfficeBuilding />
            </div>
            <input placeholder="CNPJ" id="project-cnpj" />
          </div>
          <div className="box">
            <div className="thumb">
              <MdOutlineAlternateEmail />
            </div>
            <input
              onChange={(e) => setSearch(e.target.value)}
              class="add"
              placeholder="Email do participante"
              id="project-email"
            />

            <div className="thumb add-ico" onClick={findUser}>
              <BiPlus />
            </div>
          </div>

          <div className="box user">
            <div className="header">
              <HiUsers />
              <p>Usuários</p>
            </div>
            {new_users.length !== 0 ? (
              <div className="display">
                {new_users.map((obj) => (
                  <div className="user">
                    <div className="thumb">
                      <div className="circle">
                        <p>{obj.nome.slice(0, 1)}</p>
                      </div>
                    </div>
                    <div className="text">
                      <p>{obj.nome}</p>
                      <p className="email">{obj.email}</p>
                    </div>
                    <div
                      className="thumb delete"
                      onClick={() => removeUser(obj)}
                    >
                      <HiTrash />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="display-null">
                <h2>Nenhum usuário adicionado</h2>
              </div>
            )}
          </div>

          <div className="btn-container">
            <div className="btn" onClick={handleEditProjeto}>
              <h2>EDITAR</h2>
            </div>
          </div>
        </Content>
      )}
    </Container>
  );
}
