import { Container, Header, Content } from "./styles";

import { MdClose } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { AiFillLock, AiFillProject } from "react-icons/ai";
import { HiTrash } from "react-icons/hi";
import { BiPlus } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";

import { useTools } from "../../../hooks/tools";
import { useEffect, useState } from "react";
import { useUsers } from "../../../hooks/users";
import alerta from "../../../utils/alerta";
import { useProjetos } from "../../../hooks/projetos";

import ContentLoader from "react-content-loader";

export default function User() {
  const { openTools } = useTools();
  const [classe, setClasse] = useState("user");
  const [subclass, setSubclass] = useState("free");
  const { user, editUser, setUser, getUsers, deleteUser } = useUsers();
  const { projetos, getProjetos } = useProjetos();
  const [search, setSearch] = useState("");
  const [new_project, setNewProject] = useState();

  useEffect(() => {
    if (user !== undefined) {
      if (user.classe === process.env.REACT_APP_ADMIN) {
        setClasse("admin");
      }

      setSubclass(user.subclass);

      if (user.projeto !== undefined) {
        setNewProject(projetos.find((obj) => obj.data._id === user.projeto));
      }

      var nome = document.getElementById("user-nome");
      var email = document.getElementById("user-email");

      nome.value = user.nome;
      email.value = user.email;
    }
  }, [user]);

  function handleClose() {
    openTools("user");
  }

  function checkClass(str) {
    if (str === classe) {
      return "ativ";
    }
  }

  function LoadPanel() {
    function LoadItem() {
      return (
        <div className="user">
          <div className="thumb">
            <ContentLoader
              viewBox="0 0 380 300"
              style={{ transform: `translateY(.1em)` }}
            >
              {/* Only SVG shapes */}
              <rect x="80" y="30" rx="5" ry="5" width="200" height="200" />
            </ContentLoader>
          </div>
          <div className="text">
            <ContentLoader
              viewBox="0 0 3500 600"
              style={{ transform: `translateY(.1em)` }}
            >
              {/* Only SVG shapes */}
              <rect x="0" y="90" rx="5" ry="5" width="3000" height="160" />
              <rect x="0" y="360" rx="5" ry="5" width="1000" height="80" />
            </ContentLoader>
          </div>
        </div>
      );
    }
    var x = 0;
    var result = [];

    while (x !== 12) {
      result.push(x);
      x++;
    }

    return (
      <>
        {result.map((obj) => (
          <LoadItem key={result.indexOf(obj)} />
        ))}
      </>
    );
  }

  function handleEditUser() {
    var email = document.getElementById("user-email");
    var nome = document.getElementById("user-nome");
    var senha = document.getElementById("user-senha");

    var check = true;

    if (email.value === "") {
      alerta("Preencha o email do usuário", false, "err");
      email.classList.add("red");
      check = false;
    }

    if (nome.value === "") {
      alerta("Preencha o nome do usuário", false, "err");
      nome.classList.add("red");
      check = false;
    }

    if (check) {
      email = email.value;
      senha = senha.value;
      nome = nome.value;
      var ide = user._id;

      var projeto = "";

      if (new_project !== undefined) {
        projeto = new_project.data._id;
      }

      var new_user = {
        email,
        senha,
        nome,
        classe,
        projeto,
        ide,
        subclass,
      };

      function reset() {
        setUser();
        handleClose();
        getProjetos();
        getUsers();
      }

      editUser(new_user, reset);
    }
  }

  function removeErr(ide) {
    document.getElementById(ide).classList.remove("red");
  }

  function editProjeto(ide) {
    setSearch("");
    document.getElementById("user-projeto").value = "";
    setNewProject(ide);
  }

  function removeProj() {
    setNewProject();
  }

  function checkSubclass(str) {
    if (str === subclass) {
      return "ativ";
    }
  }

  function handleDeleteUser() {
    function reset() {
      setUser();
      handleClose();
      getProjetos();
      getUsers();
    }

    function startDelete() {
      deleteUser(user._id, reset);
    }

    alerta(
      "Tem certeza que deseja excluir este usuário?",
      true,
      "err",
      startDelete
    );
  }

  return (
    <Container>
      {search !== "" && (
        <div className="users_box">
          {projetos.length !== 0 && search !== "" ? (
            projetos
              .filter(
                (obj) =>
                  obj.data.nome.toLowerCase().includes(search.toLowerCase()) ||
                  obj.data.cnpj.toLowerCase().includes(search.toLowerCase())
              )
              .map((obj) => (
                <div
                  key={obj.id}
                  className="user select"
                  onClick={() => editProjeto(obj)}
                >
                  <div
                    className="thumb"
                    style={{ backgroundColor: obj.color }}
                  ></div>
                  <div className="text">
                    <p>{obj.data.nome}</p>
                    <p className="email">{obj.data.cnpj}</p>
                  </div>
                </div>
              ))
          ) : (
            <LoadPanel />
          )}
        </div>
      )}
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="content">
          <p>Editando Usuário</p>
        </div>
        <div className="close delete" onClick={handleDeleteUser}>
          <BsTrash />
        </div>
      </Header>
      <Content>
        <div className="box">
          <div className="thumb">
            <IoIosPerson />
          </div>
          <input
            onClick={() => removeErr("user-nome")}
            placeholder="Nome"
            id="user-nome"
          />
        </div>
        <div className="box">
          <div className="thumb">
            <MdOutlineAlternateEmail />
          </div>
          <input
            onClick={() => removeErr("user-email")}
            placeholder="Email"
            id="user-email"
          />
        </div>
        <div className="box">
          <div className="thumb">
            <AiFillLock />
          </div>
          <input
            onClick={() => removeErr("user-senha")}
            placeholder="Senha"
            id="user-senha"
          />
        </div>

        <div className="box class">
          <div
            onClick={() => setClasse("user")}
            className={`class-btn ${checkClass("user")}`}
          >
            <p>CLIENTE</p>
          </div>
          <div
            onClick={() => setClasse("admin")}
            className={`class-btn ${checkClass("admin")}`}
          >
            <p>ADMINISTRADOR</p>
          </div>
        </div>
        {classe === "user" && (
          <div className="box class">
            <div
              onClick={() => setSubclass("free")}
              className={`class-btn ${checkSubclass("free")}`}
            >
              <p>FREE</p>
            </div>
            <div
              onClick={() => setSubclass("basic")}
              className={`class-btn ${checkSubclass("basic")}`}
            >
              <p>BASIC</p>
            </div>
            <div
              onClick={() => setSubclass("pro")}
              className={`class-btn ${checkSubclass("pro")}`}
            >
              <p>PRO</p>
            </div>
          </div>
        )}
        <div className="box">
          <div className="thumb">
            <MdOutlineAlternateEmail />
          </div>
          <input
            onChange={(e) => setSearch(e.target.value)}
            class="add"
            placeholder="Nome do projeto"
            id="user-projeto"
          />

          <div className="thumb add-ico" onClick={() => setNewProject()}>
            <BiPlus />
          </div>
        </div>

        <div className="box user">
          <div className="header">
            <AiFillProject style={{ opacity: "1" }} />
            <p>Projeto</p>
          </div>
          {new_project !== undefined ? (
            <div className="display">
              <div className="user">
                <div
                  className="thumb slim"
                  style={{ backgroundColor: new_project.color }}
                ></div>
                <div className="text">
                  <p>{new_project.data.nome}</p>
                  <p className="email">{new_project.data.cnpj}</p>
                </div>
                <div className="thumb delete" onClick={removeProj}>
                  <HiTrash />
                </div>
              </div>
            </div>
          ) : (
            <div className="display-null">
              <h2>Nenhum projeto selecionado</h2>
            </div>
          )}
        </div>
        <div className="btn-container">
          <div className="btn" onClick={handleEditUser}>
            <h2>EDITAR</h2>
          </div>
        </div>
      </Content>
    </Container>
  );
}
