import { Container } from "./styles";
import "./App.css";
import Menu from "./components/menu";
import Dash from "./components/dashboard";
import BlackScreen from "./components/black_screen";
import Log from "./components/login";
import Alerta from "./components/black_screen/alerta";
import Alert from "./components/black_screen/alert_label";
import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "./hooks/auth";
import Loading from "./utils/loading";
import { MdCheck } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { gapi } from "gapi-script";

const uri = process.env.REACT_APP_API_URI;

export default function App() {
  const { login, setLogin } = useAuth();
  const inputs = document.getElementsByTagName("input");
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].setAttribute("autocomplete", "off");
  }

  const token = localStorage.getItem("app-token");

  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  axios.interceptors.request.use((request) => {
    request.headers.app = "Adm";
    return request;
  });
  return (
    <Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Alert />
      <Alerta />
      {!login ? (
        <Log />
      ) : (
        <>
          <BlackScreen />
          <Menu />
          <Dash />
        </>
      )}
    </Container>
  );
}
