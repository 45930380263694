import React, { useState, useEffect } from "react";

import { Alerta } from "./styles.js";

import { MdCheck } from "react-icons/md";
import Loading from "../../../utils/loading";
import { useProjetos } from "../../../hooks/projetos.js";

export default function Alert() {

  return (
    <Alerta id="mini-alert" >
      <div className="content">
        <div className="thumb">
          <div id="mini-load" style={{ display: "none" }}>
            {Loading(50)}
          </div>
          <div id="mini-check" style={{ display: "none" }}>
            <MdCheck />
          </div>
        </div>
        <div className="text">
          <p id="mini-text">Excluindo projeto</p>
        </div>
      </div>
      <div className="progress">
        <div className="load"></div>
      </div>
    </Alerta>
  );
}
