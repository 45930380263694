import { useEffect, useState } from "react";
import { Container, Content, Header } from "./styles";
import { useTools } from "../../../hooks/tools";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { AiFillProject } from "react-icons/ai";
import { HiTrash } from "react-icons/hi";
import { BiPlus } from "react-icons/bi";
import { useProjetos } from "../../../hooks/projetos";
import axios from "axios";
import alerta from "../../../utils/alerta";

const uri = process.env.REACT_APP_API_URI;
export default function NewWorker() {
  const { openTools } = useTools();
  const [classe, setClasse] = useState("user");
  const [subclass, setSubclass] = useState("free");
  const [search, setSearch] = useState("");
  const [new_project, setNewProject] = useState();
  const { projetos, getProjetos } = useProjetos();

  function handleClose() {
    openTools("new_worker");
  }
  function removeProj() {
    setNewProject();
  }
  function createWorker() {
    if (new_project !== undefined) {
      axios
        .post(`${uri}/adm/worker`, { projeto: new_project.data._id })
        .then((res) => {
          toast.success(res.data.msg);
          setNewProject();
          handleClose();
        });
    } else {
      toast.error("Selecione um projeto");
    }
  }

  function editProjeto(ide) {
    setSearch("");
    document.getElementById("user-projeto").value = "";
    setNewProject(ide);
  }

  return (
    <Container>
      {search !== "" && (
        <div className="users_box">
          {projetos.length !== 0 &&
            search !== "" &&
            projetos
              .filter(
                (obj) =>
                  obj.data.nome.toLowerCase().includes(search.toLowerCase()) ||
                  obj.data.cnpj.toLowerCase().includes(search.toLowerCase())
              )
              .map((obj) => (
                <>
                  <div className="user select" onClick={() => editProjeto(obj)}>
                    <div
                      className="thumb"
                      style={{ backgroundColor: obj.color }}
                    ></div>
                    <div className="text">
                      <p>{obj.data.nome}</p>
                      <p className="email">{obj.data.cnpj}</p>
                    </div>
                  </div>
                </>
              ))}
        </div>
      )}
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="content">
          <p>Novo Worker</p>
        </div>
      </Header>
      <Content>
        <div className="box">
          <div className="thumb">
            <MdOutlineAlternateEmail />
          </div>
          <input
            onChange={(e) => setSearch(e.target.value)}
            class="add"
            placeholder="Nome do projeto"
            id="user-projeto"
          />

          <div className="thumb add-ico" onClick={() => setNewProject()}>
            <BiPlus />
          </div>
        </div>

        <div className="box user">
          <div className="header">
            <AiFillProject style={{ opacity: "1" }} />
            <p>Projeto</p>
          </div>
          {new_project !== undefined ? (
            <div className="display">
              <div className="user">
                <div
                  className="thumb slim"
                  style={{ backgroundColor: new_project.color }}
                ></div>
                <div className="text">
                  <p>{new_project.data.nome}</p>
                  <p className="email">{new_project.data.cnpj}</p>
                </div>
                <div className="thumb delete" onClick={removeProj}>
                  <HiTrash />
                </div>
              </div>
            </div>
          ) : (
            <div className="display-null">
              <h2>Nenhum projeto selecionado</h2>
            </div>
          )}
        </div>
        <div className="btn-container">
          <div className="btn" onClick={createWorker}>
            <h2>ENVIAR</h2>
          </div>
        </div>
      </Content>
    </Container>
  );
}
