import React, { useState, useEffect } from "react";
import { Tela, Alert, Btns, Btn } from "./styles";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";

export default function Alerta() {
  return (
    <Tela id="alert">
      <Alert>
        <h2 id="alert-err">
          <FiAlertTriangle /> Aviso{" "}
        </h2>
        <h2 id="alert-sucess">
          <AiOutlineCheckCircle /> Sucesso{" "}
        </h2>
        <p id="alert-text"></p>
        <Btns id="bool">
          <Btn id="alert-sim" className="sim">
            <p>Sim</p>
          </Btn>
          <Btn id="alert-nao">
            <p>Não</p>
          </Btn>
        </Btns>
        <Btns id="not-bool">
          <Btn id="alert-ok" className="sim">
            <p>Ok</p>
          </Btn>
        </Btns>
      </Alert>
    </Tela>
  );
}
