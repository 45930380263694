import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  align-items: center;
  justify-content: center;

  &.ativ {
    display: flex;
  }
`;
