import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 26;
  color: black;
  font-size: 1.1em;

`;

export const Block = styled.div`
  width: 45em;
  height: 35em;
  display: flex;
  flex-direction: column;
  background-color: rgba(20, 0, 10, 0.8);
  border-radius: 5px;
  overflow: hidden;
`


export const Header = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  background-color: rgba(15, 15, 15);

  .text {
    width: calc(100% - 2.5em);
    display: flex;
    align-items: center;
    color: white;
    opacity: 0.8;
    svg {
      margin-right: 0.5em !important;
    }

    .btn {
      width: max-content;
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0px 40px !important;
      background-color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.01);
      }

      &.ativ {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .close {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(255, 255, 255, 0.01);
    border-left: 1px solid rgba(0, 0, 0, 0.7);
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      background-color: rgba(200, 0, 0, 0.2);
    }
  }
`;

export const Workers = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  
  .btn {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(15, 15, 15);
    opacity: .6;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }

    &.ativ {
      opacity: 1;
    }
  
    p {
      font-size: .8em;
      padding: 0 20px !important;
      color: white;
    }
  }
`

export const Display = styled.div`
  width: 100%;
  height: calc(100% - 2.5em);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: overlay;
  color: white;

  &.ativ {
    height: calc(100% - 2.5em);
  }

  p {
    opacity: 0.8;
    font-size: 0.8em;
  }

  ::-webkit-scrollbar {
    width: 13px;
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: black;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 20);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(25, 25, 25);
  }

  .line-disp {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .line {
    width: 100%;
    height: max-content;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.white {
      height: 2em;
    }
    p {
      padding: 5px 0 !important;
      margin-left: 1em !important;

      &.success {
        color: rgba(0, 200, 0);
      }

      &.err {
        color: rgba(200, 0, 0)
      }
    }
  }
`;
