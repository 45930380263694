import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import alerta from "../utils/alerta";

const AuthContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function AuthProvider({ children }) {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState();
  const [infos, setInfos] = useState();

  function getAuth() {
    axios.get(`${uri}/auth`).then((res) => {});
  }

  async function tryLogin(user) {
    console.log("tryLogin called with user:", user);
    console.log("Origem da chamada:", new Error().stack);

    await axios
      .post(`${uri}/auth`, user)
      .then((res) => {
        console.log("Login response:", res);

        if (res.status === 200) {
          if (res.data.user_db.classe === process.env.REACT_APP_ADMIN) {
            setLogin(true);
            setUser(res.data.id);
            localStorage.setItem("app-token", res.data.token);
            localStorage.setItem("user_id", res.data.user_db._id);
            localStorage.setItem("classe", res.data.user_db.classe);
          } else {
            alerta("Usuário não autorizado", false, "err", () => {});
          }
        }
      })
      .catch((e) => {
        console.log("Login error:", e);

        alerta("Usuário não encontrado", false, "err", () => {});
      });
  }

  async function tryLoginGoogle(google, accessToken) {
    try {
      await axios.post(`${uri}/google`, { google, accessToken }).then((res) => {
        if (res.status === 200) {
          setLogin(true);
          setUser(res.data.id);
          localStorage.setItem("app-token", res.data.token);
          localStorage.setItem("user_id", res.data.user_db._id);
          localStorage.setItem("projeto", res.data.user_db.projeto);
          localStorage.setItem("classe", res.data.user_db.classe);
          window.location.reload();
        }
      });
    } catch (e) {
      alerta("Usuário não autorizado", false, "err", () => {});
    }
  }

  function Logout() {
    setLogin();
    setUser();
    localStorage.clear();
  }

  useEffect(() => {
    axios
      .post(`${uri}/auth/check`, { user: localStorage.getItem("user_id") })
      .then((res) => {
        if (
          res.status === 200 &&
          localStorage.getItem("app-token") !== null &&
          localStorage.getItem("user_id") !== null &&
          localStorage.getItem("classe") === process.env.REACT_APP_ADMIN
        ) {
          setUser(localStorage.getItem("user_id"));
          setLogin(true);
          setInfos(res.data);
        } else {
          setLogin(false);
        }
      })
      .catch((err) => {
        setLogin(false);
      });
  }, []);

  return (
    <AuthContext.Provider value={{ login, tryLogin, Logout, tryLoginGoogle }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  const { login, tryLogin, Logout, tryLoginGoogle } = context;
  return { login, tryLogin, Logout, tryLoginGoogle };
}
